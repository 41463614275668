import { createBrowserRouter } from "react-router-dom";
import Main from "../../Layout/Main";
import Achievements from "../../Pages/Achievements/Achievements/Achievements";
import AccountSettings from "../../Pages/AdminDashboard/AccountSettings/AccountSettings";
import AdminLogin from "../../Pages/AdminDashboard/AdminLogin/AdminLogin";
import MainDashboard from "../../Pages/AdminDashboard/MainDashboard/MainDashboard";
import Overview from "../../Pages/AdminDashboard/Overview/Overview/Overview";
import Users from "../../Pages/AdminDashboard/Users/Users/Users";
import Careers from "../../Pages/Careers/Careers/Careers";
import ContactUs from "../../Pages/ContactUs/ContactUs/ContactUs";
import DigitalMarketing from "../../Pages/DigitalMarketing/DigitalMarketing/DigitalMarketing";
import Error from "../../Pages/Error/Error";
import Home from "../../Pages/Home/Home/Home";
import ItConsulting from "../../Pages/ItConsulting/ItConsulting/ItConsulting";
import ItManagedServices from "../../Pages/ItManagedServices/ItManagedServices/ItManagedServices";
import ItStuffing from "../../Pages/ItStuffing/ItStuffing/ItStuffing";
import ItTraining from "../../Pages/ItTraining/ItTraining/ItTraining";
import AndroidDeveloper from "../../Pages/JobOpenings/AndroidDeveloper";
import AngularDeveloper from "../../Pages/JobOpenings/AngularDeveloper";
import BackbaseDeveloper from "../../Pages/JobOpenings/BackbaseDeveloper";
import DataPowerDeveloper from "../../Pages/JobOpenings/DataPowerDeveloper";
import FullStackDeveloper from "../../Pages/JobOpenings/FullStackDeveloper";
import IosDeveloper from "../../Pages/JobOpenings/IosDeveloper";
import JavaArchitect from "../../Pages/JobOpenings/JavaArchitect";
import ReactJsDeveloper from "../../Pages/JobOpenings/ReactJsDeveloper";
import Jobs from "../../Pages/Jobs/Jobs";
import OurStory from "../../Pages/OurStory/OurStory/OurStory";
import Partners from "../../Pages/Partners/Partners/Partners";
import Portfolio from "../../Pages/Portfolio/Portfolio";
import PowerBi from "../../Pages/PowerBi/PowerBi";
import SoftwareDevelopment from "../../Pages/SoftwareDevelopment/SoftwareDevelopment/SoftwareDevelopment";
import UnderConstruction from "../../Pages/UnderConstruction/UnderConstruction";
import PrivateRoute from "../PrivateRoutes/PrivateRoute";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main></Main>,
        children: [
            { path: "/", element: <Home></Home> },
            { path: "/home", element: <Home></Home> },
            {
                path: "/about", element: <OurStory />
            },
            {
                path: "/about/our-story", element: <OurStory />
            },
            {
                path: "/about/partners", element: <Partners />
            },
            {
                path: "/about/achievements", element: <Achievements />
            },
            {
                path: "/careers", element: <Careers></Careers>
            },
            {
                path: "/careers/android-developer", element: <AndroidDeveloper />
            },
            {
                path: "/careers/java-architect", element: <JavaArchitect />
            },
            {
                path: "/careers/full-stack-developer", element: <FullStackDeveloper />
            },
            {
                path: "/careers/angular-developer", element: <AngularDeveloper />
            },
            {
                path: "/careers/ios-developer", element: <IosDeveloper />
            },
            {
                path: "/careers/data-power-developer", element: <DataPowerDeveloper />
            },
            {
                path: "/careers/backbase-developer", element: <BackbaseDeveloper />
            },
            {
                path: "/careers/reactjs-developer", element: <ReactJsDeveloper />
            },
            {
                path: "/portfolio", element: <Portfolio />
            },
            {
                path: "/contact-us", element: <ContactUs />
            },
            {
                path: "/power-bi", element: <PowerBi />
            },
            {
                path: "/software-development", element: <SoftwareDevelopment />
            },
            {
                path: "/it-consulting", element: <ItConsulting />
            },
            {
                path: "/it-managed-services", element: <ItManagedServices />
            },
            {
                path: "/it-training", element: <ItTraining />
            },
            {
                path: "/it-staffing", element: <ItStuffing />
            },
            {
                path: "/digital-marketing", element: <DigitalMarketing />
            },
            {
                path: "/jobs", element: <Jobs />
            },
            {
                path: "*",
                element: <Error />
            }
        ]
    },
    {
        path: "/adminDashboard",
        element: <MainDashboard></MainDashboard>,
        children: [
            { path: "/adminDashboard", element: <PrivateRoute><Overview /></PrivateRoute> },
            { path: "/adminDashboard/overview", element: <PrivateRoute><Overview /></PrivateRoute> },
            { path: "/adminDashboard/account-settings", element: <PrivateRoute><AccountSettings /></PrivateRoute> },
            { path: "/adminDashboard/users", element: <PrivateRoute><Users /></PrivateRoute> },
            { path: "/adminDashboard/settings", element: <PrivateRoute><UnderConstruction /></PrivateRoute> },
        ]
    },
    {
        path: "/login", element: <AdminLogin />
    },
]);

export default router;
import React, { useEffect } from 'react';
import FileUploadForm from '../../Components/FileUploadForm/FileUploadForm';
import ReactGA from 'react-ga';
import { db } from '../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";


const DataPowerDeveloper = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.event({
            category: "Job Openings",
            action: "Data Power Developer",
            label: "Data Power Developer",
        });
    }, []);

    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'Data Power Developer'
    };
    push(ipPageRef, pageData);


    return (
        <div className='px-10'>
            <div className='py-20 bg-sky-500 mb-10'>
                <h1 className='text-center text-6xl text-white'>Data Power Developer</h1>
            </div>
            <h1 className='text-2xl'><span className='font-bold'>Location:</span>     Phoenix ,Az</h1>
            <h1 className='font-bold text-2xl my-3'>About this position :</h1>
            <p className=' text-gray-600'>
                Working on IBM Data Power firmware deployment, Policy Configuration and application Integration <br />
                The Senior IBM Power8/9 System Administrator will work as part of a team, which may be virtual, global, and/or multifunctional addressing complex issues. <br /> Remote work is a possibility. <br />
                Developing SOAP and REST based web services with Security implementation including SSL/TLS, WS-Security,Firmware
                deployment <br />
                Working on AAA,xslt, xsd,wsdl
            </p>
            <h1 className='font-bold text-2xl my-3'>Duties and Responsibilities :</h1>
            <p className=' text-gray-600'>
                Apply deep and broad technical background and knowledge of industry trends to operate several critical or high risk technology areas/customer groups. <br />
                Integrate technical knowledge and business understanding to create superior solutions for company and for customers. <br />
                Mentor/consult with team members, other organizations, customers, and vendors on complex issues. <br />
                Incident Management: Resolve technical and some business incidents independently. <br />
                Escalation Management: identify, mange, and lead escalations through leadership. <br />
                Problem Management: Proactively and reactively look for solutions to prevent complex problems from occurring across
                teams/technologies. <br />
                Change Management: Independently review and verify changes/solutions of high complexity and risk to meet customer
                and/or trade/company Information Technology infrastructure needs <br />
                Patch and Security Management: Provide input to security policies. Proactively monitor the environment for patch
                compliance. <br /> Analyze patches for compatibility with each customer or internal infrastructure environment. Approve patch and security changes. <br />
                Configuration Management: Ensure Configuration Management Database (CMDB) entries are complete and accurate. <br />
                Solution Design: Apply company solutions or, when justified by business needs, create solutions to meet highly complex
                customer and/or trade/company
                IT infrastructure needs. Provide feedback to create new company solution sets. <br />
                Quality: May provide feedback/influence change in internal and/or vendor-provided products/service offerings. <br />
                Project Management: Participate, propose, and/or lead complex customer and internal projects, including
                transformation, across technology/ customers and/or internal businesses/end user’s areas. Provide review/advice to
                projects inside/outside responsibility areas. <br />
                Customer Relationship Management: Influence with customers and/or internal businesses/end users on a broad range
                of technical and operational topics. Becoming a trusted advisor to the customers and/or internal businesses/end users. <br />
                Develop and grow assigned customers and/or internal businesses/end users account relationships.
            </p>
            <h1 className='font-bold text-2xl my-3'>Qualifications</h1>
            <p className=' text-gray-600'>
                Bachelor’s degree in Computer Science or closely related field of study <br />
                8+ years IT experience is required. <br />
                Must have hands on experience with Security policy within DataPower <br />
                Must have knowledge of DataPower auditing and troubleshooting framework <br />
                Must be familiar with DP CLI <br />
                Experience with migrating Application specific objects, proxies and other objects between environments <br />
                Experience with automated deployments strategies with DP <br />
                Must have experience with version control and build management of DataPower objects <br />
                Must have at least 4+ years of DataPower hands on experience SSL/TLS <br />
                Can securely configure the Datapower servers as internal and external proxies for the FHIR API's <br />
                The data Interchange is JSON , not XML
                Oauth Configuration <br />
                Should be able to design, document and implement DataPower services. <br />
                Experience with Web Services Proxy and Multiprotocol Gateway
            </p>
            <h1 className='font-bold text-2xl my-3'>Required Skills/Experience</h1>
            <p className=' text-gray-500'>
                Five or more years of experience administering IBM Power 8/9 Systems environments running IBM for a multi-
                system and partitioned environment. <br />
                Requires in depth experience in the following areas: <br /> Work Management, Performance Analysis and Tuning, Database Management, Backup and Recovery, Security and Compliance, Storage Management, Journal
                Management, Hardware/Software Installation and Maintenance, Communications, and Networking. <br />
                Linux Administration skills. <br />
                Must possess a current CompTia S+ Certification. <br />
                Must currently possess a valid DoD Secret Security Clearance. TCI will assist with transfers of clearances.
                Technical certification. <br />
                Partially remote, but must be within driving distance of Fort Lee, VA.
            </p>
            <h1 className='font-bold text-2xl my-3'>Recommended Skills/Experience</h1>
            <p className=' text-gray-500'>
                Developing SOAP and REST based web services with Security implementation including SSL/TLS, WS-Security <br />
                Firmware deployment <br />
                Working on XSLT, XSD,WSDL.
            </p>
            <FileUploadForm />
        </div>
    );
};

export default DataPowerDeveloper;
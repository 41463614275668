import React from 'react';
import DristributionPartners from '../DistributionPartners/DristributionPartners';
import FinancialPartners from '../FinancialPartners/FinancialPartners';
import TechnologyPartners from '../TechnologyPartners/TechnologyPartners';
import { Helmet } from 'react-helmet-async';
import { db } from '../../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";


const Partners = () => {
    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'Partners'
    };
    push(ipPageRef, pageData);


    return (
        <div>
            <Helmet>
                <title>BRITS - Partners</title>
                <meta
                    name="description"
                    content="BRITS is a leading provider of IT services and solutions. We are a team of experts who are passionate about technology and innovation. We are committed to helping our clients achieve their business goals."
                />
            </Helmet>
            <TechnologyPartners />
            <FinancialPartners />
            <DristributionPartners />
        </div>
    );
};

export default Partners;
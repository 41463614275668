import React from 'react';
import { TbDeviceDesktopAnalytics } from "react-icons/tb"
import { Link } from 'react-router-dom';
const Portfolio = () => {
    return (
        <div className='py-14 px-4 md:px-10 lg:px-16 xl:px-36 bg-base-200'>
            <h1 className='text-5xl text-center font-semibold'><TbDeviceDesktopAnalytics className='inline mr-4' />PORTFOLIO</h1>
            <div className='w-3/5 bg-red-100  mx-auto border-2 border-red-600 rounded-3xl h-[2100px] md:h-[1400px] lg:h-[1100px] my-5 relative'>
                <div className='absolute rounded-2xl border-2 border-red-600 h-[2050px] md:h-[1350px] lg:h-[1050px] w-[150%] top-6 left-[-25%] mx-auto bg-white p-5 max-w-screen-2xl'>
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-0 items-center justify-around'>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/f/f8/Splunk_logo.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/ServiceNow_logo.svg/2560px-ServiceNow_logo.svg.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in-right"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://erepublic.brightspotcdn.com/a8/48/c5e97c51408d8347280594ef84a7/mulesoft-logo380x258.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Terraform_Logo.svg/1280px-Terraform_Logo.svg.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in-right"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://download.logo.wine/logo/Nutanix/Nutanix-Logo.wine.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://www.ipxo.com/app/uploads/2022/05/PhoenixNap-LOGO1024X225-01.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in-right"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://www.freepnglogos.com/uploads/vmware-png-logo/partners-login-vmware-png-logo-10.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e3/Jenkins_logo_with_title.svg/2560px-Jenkins_logo_with_title.svg.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in-right"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Citrix.svg/1200px-Citrix.svg.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/7/71/HubSpot_Logo.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in-right"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Dell_Logo.png/1200px-Dell_Logo.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/9/93/Veeam_logo.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in-right"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://download.logo.wine/logo/Pegasystems/Pegasystems-Logo.wine.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://download.logo.wine/logo/Microsoft_Azure/Microsoft_Azure-Logo.wine.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in-right"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://www.freelogovectors.net/svg06/advancedmd-logo.svg" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://www.intelerad.com/en/wp-content/uploads/sites/2/2022/08/Epic-logo-transparent-e1659637275781.png" alt="" className='w-1/2 mx-auto'
                                data-aos="zoom-in-right"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://1000logos.net/wp-content/uploads/2021/09/HPE-Logo.png" alt="" className='w-1/2 mx-auto'
                                data-aos="zoom-in"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://download.logo.wine/logo/DigitalOcean/DigitalOcean-Logo.wine.png" alt="" className='w-3/4 mx-auto'
                                data-aos="zoom-in-right"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://i0.wp.com/dotweekly.com/wp-content/uploads/2018/04/salesforce-logo.png" alt="" className='w-1/2 mx-auto'
                                data-aos="zoom-in"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://zeevector.com/wp-content/uploads/Microsoft-Azure-DevOps-logo.png" alt="" className='w-1/2 mx-auto'
                                data-aos="zoom-in-right"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Ansible_logo.svg/1664px-Ansible_logo.svg.png" alt="" className='w-3/4 md:w-1/2 mx-auto p-5'
                                data-aos="zoom-in"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <Link to="/power-bi">
                                <img src="https://diginationmea.com/w/wp-content/uploads/2021/06/powerbi-logo.png" alt="" className='w-3/4 md:w-1/2 mx-auto p-5'
                                    data-aos="zoom-in-right"
                                    data-aos-offset="200"
                                    data-aos-duration="1500"
                                    data-aos-easing="ease-in-out"
                                /></Link>
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/1024px-Amazon_Web_Services_Logo.svg.png" alt="" className='w-3/4 md:w-1/2 mx-auto p-5'
                                data-aos="zoom-in"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                        <div className='h-[170px] flex items-center'>
                            <img src="https://png.monster/wp-content/uploads/2020/11/cloud_lockup-669717fc.png" alt="" className='w-3/4 md:w-1/2 mx-auto p-5'
                                data-aos="zoom-in-right"
                                data-aos-offset="200"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Portfolio;
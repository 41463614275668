import React from 'react';
import banner from "../../../Assets/Images/digital-marketing.gif";


const Banner = () => {
    return (
        <div className="grid grid-cols-1 lg:px-8 md:grid-cols-2 items-center py-20 justify-center">
            <div>
                <div className='flex justify-center'>
                    <h1 className='text-center text-5xl font-semibold'>Grow Your <span className='text-red-500'>Business</span></h1>
                </div>
            </div>
            <div>
                <img src={banner} alt="" className='mx-auto' />
            </div>
        </div>
    );
};

export default Banner;
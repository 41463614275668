import React from 'react';
import Lottie from "lottie-react";
import networkAnim from "../../../Assets/Animations/network.json";
import implementAnim from "../../../Assets/Animations/implementation.json";
import maintainanceAnim from "../../../Assets/Animations/maintainance-data.json";
import securityAnim from "../../../Assets/Animations/security.json";
import policyAnim from "../../../Assets/Animations/security-policy.json";
import complianceAnim from "../../../Assets/Animations/compliance.json";
import softwareAnim from "../../../Assets/Animations/Software-development.json";
import consultingAnim from "../../../Assets/Animations/consulting.json";
import agileAnim from "../../../Assets/Animations/agile-consulting.json";
import architectureAnim from "../../../Assets/Animations/management.json";
import integrationAnim from "../../../Assets/Animations/data-integration.json";
import biAnim from "../../../Assets/Animations/data-bi.json";

const ItConsultingServices = () => {
    return (
        <div className='px-10'>
            <h1 className='text-5xl text-center'>Welcome to Our IT Consulting Services
            </h1>
            <p className='my-5'>Our team of experienced IT professionals offers a wide range of consulting services to help your business thrive in today's digital landscape. From network infrastructure and cybersecurity to software development and data management, we have the expertise to help you achieve your goals.
            </p>
            <p className='my-5'><span className='text-3xl'>Network Infrastructure Consulting</span>
                <br />
                One of the key areas we specialize in is network infrastructure consulting. We can help you design, implement, and maintain a network that is robust, secure, and scalable to meet the demands of your business. Our services include:
            </p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2">
                <figure className='p-5 md:w-1/2 lg:w-[350px]'>
                    <Lottie
                        animationData={networkAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Network design and architecture</h2>
                    <p>We can help you create a network that is tailored to your specific needs and requirements. We'll work with you to understand your current and future business objectives, and design a network that will help you achieve them.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[250px]'>
                    <Lottie
                        animationData={implementAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Network implementation</h2>
                    <p>Once the design is finalized, we can help you implement the network, including the installation and configuration of network devices, servers, and other equipment.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={maintainanceAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Network maintenance and support</h2>
                    <p>We can provide ongoing maintenance and support for your network, including software updates, security patches, and troubleshooting.
                    </p>
                </div>
            </div>
            <p className='my-5'><span className='text-3xl'>Cybersecurity Consulting
            </span>
                <br />
                In today's digital age, cybersecurity is more important than ever. Our cybersecurity consulting services can help you protect your business from cyber threats, such as hacking and data breaches. Our services include:
            </p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={securityAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Security assessments
                    </h2>
                    <p>We can conduct a thorough security assessment of your current IT infrastructure, identifying vulnerabilities and potential weaknesses.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={policyAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Security policy development</h2>
                    <p>Based on our assessment, we can help you develop and implement a comprehensive security policy that includes best practices for protecting your business from cyber threats.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={complianceAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Compliance consulting</h2>
                    <p>We can assist you in meeting various compliance requirements, such as HIPAA, PCI-DSS, and SOC 2.
                    </p>
                </div>
            </div>
            <p className='my-5'><span className='text-3xl'>Software Development Consulting
            </span>
                <br />
                Our team of experienced software developers can help you build and optimize your software applications. Our services include:
            </p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={softwareAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Software development</h2>
                    <p>Whether you're looking to build a new application from scratch or improve an existing one, our team of developers can help you create a software solution that meets your business needs.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={consultingAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Technical consulting</h2>
                    <p>We can help you identify the best technologies and tools to use for your software development projects, and provide guidance and best practices to ensure your software is built to the highest standards.

                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[170px]'>
                    <Lottie
                        animationData={agileAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Agile consulting</h2>
                    <p>We can help you implement agile methodologies in your software development process, allowing for faster development and delivery of new features and capabilities.
                    </p>
                </div>
            </div>
            <p className='my-5'><span className='text-3xl'>Data Management Consulting
            </span>
                <br />
                We can help you design and implement a data management strategy that is tailored to your specific business needs. Our services include:
            </p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={architectureAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Data architecture</h2>
                    <p>We can help you design a data architecture that is optimized for performance, scalability, and security.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={integrationAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Data integration</h2>
                    <p>
                        We can help you integrate your data with other systems, such as CRM and ERP systems, to ensure that your business data is always up-to-date and accurate.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={biAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Data warehousing and BI</h2>
                    <p>
                        We can help you design and implement data warehousing and business intelligence solutions that provide insights into your business data, allowing you to make better-informed decisions.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ItConsultingServices;
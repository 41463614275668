import React from 'react';
import Lottie from "lottie-react";
import bannerAnimation from "./../../../Assets/Animations/banner-lottie.json";

const Banner = () => {
    return (
        <div className="hero w-[90%] mx-auto my-5 h-[600px] md:h-[800px] lg:h-[650px] xl:h-[750px]">
            <div className="md:block lg:flex w-full">
                <div className="lg:w-1/2 flex justify-center items-center" data-aos="fade-right" data-aos-offset="200"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out">
                    <div>
                        <h1 className='text-5xl md:text-[90px] lg:text-[78px]'>One Stop Solution <br /> For All Your</h1>
                        <h1 className='text-7xl md:text-7xl font-semibold text-red-500'>IT NEEDS</h1>
                    </div>
                </div>
                <div className="flex-shrink-0 w-full lg:w-1/2 " data-aos="fade-left" data-aos-offset="200"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out">
                    <Lottie animationData={bannerAnimation} className="h-[400px] md:h-[400px] lg:h-[600px] xl:h-[600px]" />
                </div>
            </div>
        </div>
    );
};

export default Banner;
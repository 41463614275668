import React from 'react';
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";
import errorAnimation from "../../Assets/Animations/error.json";
import { Helmet } from 'react-helmet-async';

const Error = () => {
    return (
        <section className="flex items-center h-[600px]">
            <Helmet>
                <title>Page Not Found - 404</title>
                <meta
                    name="description"
                    content="BRITS is a leading provider of IT services and solutions. We are a team of experts who are passionate about technology and innovation. We are committed to helping our clients achieve their business goals."
                />
            </Helmet>
            <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
                <Lottie animationData={errorAnimation}></Lottie>
                <p className="text-3xl">Looks like the page you are searching for is not available</p>
                <Link to="/" className="px-8 py-3 rounded bg-blue-400 text-xl font-bold text-white">Back to homepage</Link>
            </div>
        </section>
    );
};

export default Error;
import React, { useEffect } from 'react';
import FileUploadForm from '../../Components/FileUploadForm/FileUploadForm';
import ReactGA from 'react-ga';
import { db } from '../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";


const ReactJsDeveloper = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.event({
            category: "Job Openings",
            action: "ReactJS Developer",
            label: "ReactJS Developer",
        });
    }, []);

    const ipPageRef = ref(db, "pageViewData");
    const pageData = {
        pageName: "ReactJS Developer",
    };
    push(ipPageRef, pageData);


    return (
        <div className='px-10'>
            <div className='py-20 bg-sky-500 mb-10'>
                <h1 className='text-center text-6xl text-white'>ReactJS Developer</h1>
            </div>
            <h1 className='text-2xl'><span className='font-bold'>Location:</span>     Phoenix ,Az</h1>
            <h1 className='font-bold text-2xl my-3'>Job Description:</h1>
            <p className=' text-gray-600'>
                Strong knowledge in Web technologies on Angular/ReactJS, Bootstrap/Material design, HTML5, CSS3, JavaScript, Bootstrap, JQuery, JSON <br />
                Expert on server-side CSS pre-processing platforms, such as LESS and SASS <br />
                Hands on experience one or more frontend frameworks: Angular/ReactJS or are a quick learner <br />
                Experience working with styled components, familiarity with RESTful APIs
                Create and maintain UI guidelines and templates. <br />
                Experience in design and implementation of service interface and public APIs <br />
                Ability to rapidly prototype and adjust in response to customer feedback <br />
                Experience with browser testing and debugging. <br /> Understanding of all major browsers and the special considerations required for their various quirks <br />
                Experience in desktop & mobile development, design patterns and principles <br />
                Strong programming background and analytical skills <br />
                Knowledge of cross-browser testing
            </p>
            <h1 className='font-bold text-2xl my-3'>Qualifications</h1>
            <p className=' text-gray-600'>
                B.A or B.S or B.tech / Masters  Required
            </p>
            <h1 className='font-bold text-2xl my-3'>Required Skills/Experience</h1>
            <p className=' text-gray-500'>
                JavaScript, React
            </p>
            <h1 className='font-bold text-2xl my-3'>Recommended Skills/Experience</h1>
            <p className=' text-gray-500'>
                AWS <br /> Design Patterns <br />
                CI CD Technologies
            </p>
            <FileUploadForm />
        </div>
    );
};

export default ReactJsDeveloper;
import React from 'react';
import Lottie from "lottie-react";
import stuffAnim from "../../../Assets/Animations/staff.json"
import permanentStuffAnim from "../../../Assets/Animations/permanent-stuffing.json"
import contractStuffAnim from "../../../Assets/Animations/contract.json"
import recruitmentAnim from "../../../Assets/Animations/recruitment-stuff.json"
import qualifyAnim from "../../../Assets/Animations/qualify.json"
import costAnim from "../../../Assets/Animations/cost.json"
import graphAnim from "../../../Assets/Animations/chartNew.json"



const ItStuffingServices = () => {
    return (
        <div className='px-10'>
            <h1 className='text-5xl text-center'>IT Staffing Solutions for Businesses
            </h1>
            <p className='my-5'>Our IT staffing solutions are designed to help businesses like yours find the right IT professionals to meet your specific needs. Whether you need short-term contract staff, long-term employees, or contract-to-hire staff, our team of experienced recruiters can help you find the right people for the job.
            </p>
            <p className='my-5'><span className='text-3xl'>Our Staffing Services
            </span>
                <br />
                We offer a wide range of IT staffing services, including:
            </p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2">
                <figure className='p-5 md:w-1/2 lg:w-[350px]'>
                    <Lottie
                        animationData={stuffAnim}
                        className='w-full' ></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Contract Staffing
                    </h2>
                    <p>We can provide contract staff to help you meet your project deadlines or to fill in for employees who are on leave. Our contract staff is pre-screened and qualified, so you can be sure that they have the skills and experience you need.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[350px]'>
                    <Lottie
                        animationData={permanentStuffAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Permanent Staffing
                    </h2>
                    <p>We can help you find long-term employees to join your team on a full-time basis. Our recruiters will work with you to understand your specific needs and requirements, and will only present candidates who meet those criteria.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[350px]'>
                    <Lottie
                        animationData={contractStuffAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Contract-to-Hire Staffing
                    </h2>
                    <p>This is a popular option for businesses that are looking to fill a position on a contract basis before committing to a long-term hire. This allows you to evaluate the employee's skills and experience before making a permanent job offer.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[250px]'>
                    <Lottie
                        animationData={recruitmentAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">IT Recruitment Process Outsourcing (RPO) :
                    </h2>
                    <p>We can handle all your IT recruitment process from sourcing, screening, interviewing, reference check and on-boarding, which save time and resources for your organization.
                    </p>
                </div>
            </div>
            <p className='my-5'><span className='text-3xl'>Our Staffing Advantage
            </span>
                <br />
                Access to a vast pool of IT talent: We have an extensive network of IT professionals, which gives us access to a wide range of talent.
            </p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={qualifyAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Pre-screening and Qualifying</h2>
                    <p>All of our candidates are pre-screened and qualified to ensure that they have the necessary skills and experience for the job.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[250px]'>
                    <Lottie
                        animationData={costAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Cost-effective</h2>
                    <p>Our staffing services are a cost-effective way to find the right IT professionals for your business, without the need for you to invest time and resources into the recruitment process.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={graphAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Scalability</h2>
                    <p>We can handle both high volume and niche recruiting needs, for any type of position, whether it's a one-time project or a long-term commitment.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ItStuffingServices;
import React from 'react';
import Lottie from "lottie-react";
import networkAnim from "../../../Assets/Animations/network.json";
import codeAnim from "../../../Assets/Animations/code.json";
import mobileAnim from "../../../Assets/Animations/mobile.json";
import applicationAnim from "../../../Assets/Animations/application.json";
import optimizationAnim from "../../../Assets/Animations/optimization.json";

const Services = () => {
    return (
        <div className='px-10'>
            <h1 className='text-5xl text-center'>Software Consulting and Development</h1>
            <p className='my-5'>Our development team, BlackRock IT Solutuons Labs, provides software consulting services with rapid turnaround. We will write original programming that precisely matches your business needs, in any number of media.</p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2">
                <figure className='p-5 md:w-1/2 lg:w-[350px]'>
                    <Lottie className='w-full' animationData={networkAnim}></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">System Development Life Cycle (SDLC)</h2>
                    <p>We integrate several SDLC methodologies into our development process, choosing standards that suit our client’s unique needs. The Agile approach is best practice when flexibility and collaboration are key, whereas the Waterfall method works best for straightforward programming where deadlines are crucial. BlackRock IT Solutuons’s adherence to these industry methodologies ensures that the service we give is the best it can be.</p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[250px]'>
                    <Lottie className='w-full' animationData={codeAnim}></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Website Design and Development</h2>
                    <p>Online presence is growing more and more important for businesses. Our representatives can create professional, functional, and enticing websites that showcase your company. We design responsive sites that adapt for mobile viewers, allowing easy access for any client.</p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie className='w-full' animationData={mobileAnim}></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Mobile Applications</h2>
                    <p>BlackRock IT Solutuons can create a custom app for your company, allowing easy mobile access to your information or services for anyone. We can write for iOS, Android, and BlackBerry to ensure maximum market access.</p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[450px]'>
                    <Lottie className='w-full' animationData={applicationAnim}></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Custom Business Applications</h2>
                    <p>Every business has unique needs. We can help build custom applications which do precisely what you want. Our experts can implement or expose technologies such as C#, Visual Basic, WCF and other APIs, XML, SOAP, or WDSL, working in architectures like model-view-controller (MVC) and service-oriented architecture (SOA). Add to that open source platforms like Linux, Apache, Perl, PHP, MySQL, CMS, and e-commerce platforms, and we are well-equipped to create exactly the experience you desire.</p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie className='w-full' animationData={optimizationAnim}></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        System Optimization</h2>
                    <p>We will use our experience to keep your systems working at peak efficiency. Through services like database management and continuous monitoring, we will make sure your applications are constantly optimized.</p>
                </div>
            </div>
        </div>
    );
};

export default Services;
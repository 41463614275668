import React, { useEffect } from 'react';
import Contact from '../Contact/Contact';
import Main from '../Main/Main';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';
import { db } from '../../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";

const Careers = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'Careers'
    };
    push(ipPageRef, pageData);


    return (
        <div>
            <Helmet>
                <title>Careers</title>
                <meta
                    name="description"
                    content="BRITS is a leading provider of IT services and solutions. We are a team of experts who are passionate about technology and innovation. We are committed to helping our clients achieve their business goals."
                />
            </Helmet>
            <Main></Main>
            <Contact></Contact>
        </div>
    );
};

export default Careers;
import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from "firebase/database";

const MostPageView = () => {
    const [pageViewData, setPageViewData] = useState(null);
    useEffect(() => {
        const db = getDatabase();
        const dbRef = ref(db, 'pageViewData');
        onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            setPageViewData(data);
        });
    }, []);
    // create an array of data with pageName and number of same pageName count from pageViewData and sort it by number of pageName

    const newData = [];
    for (const key in pageViewData) {
        const element = pageViewData[key];
        newData.push(element.pageName);
    }
    const pageName = [...new Set(newData)];
    const pageNameCount = pageName.map(name => newData.filter(item => item === name).length);
    const pageNameData = pageName.map((name, index) => [name, pageNameCount[index]]);
    pageNameData.unshift(["Page Name", "Popularity"]);
    pageNameData.sort((a, b) => b[1] - a[1]);

    // console.log(pageNameData);


    return (
        <div className='p-3 border border-[#bfbbbb] rounded-xl'>
            <h1 className='text-2xl font-bold text-center py-2 mb-5'>Most Visited Pages</h1>
            <hr />
            <ul className='flex flex-col gap-2'>
                {
                    pageNameData.map((item, index) => {
                        if (index !== 0) {
                            return (
                                <li key={index} className='flex justify-between'>
                                    <span>{item[0]}</span>
                                    <span>{item[1]}</span>
                                </li>
                            )
                        }
                    })
                }
            </ul>
        </div>
    );
};

export default MostPageView;
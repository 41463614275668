import React from 'react';

const TechnologyPartners = () => {
    return (
        <div className='my-20'>
            <h1 className='text-red-500 text-3xl text-center'>MEET OUR PARTNERS</h1>
            <h1 className='text-center text-5xl'>TECHNOLOGY PARTNERS</h1>
            <div className='grid grid-cols-5 md:grid-cols-8 lg:grid-cols-12 w-4/5 mx-auto borderCss my-5 p-5 justify-items-center gap-2'>
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/21/3f1e4cb3d1e53e1bab628025d822b9a2/1111-systems.png?imageOpt=1&fit=bounds&width=49" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/88dbf4d0ff7c19f813cf4828f75c92c3/1wire-84.png?imageOpt=1&fit=bounds&width=63" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/d2d668a9dbea2b1305949336c4717c60/png-365-logo-transperent.png?imageOpt=1&fit=bounds&width=339" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/98b8ad4e4ea2833dc3b582af470e669f/4voice-01.png?imageOpt=1&fit=bounds&width=87&crop=873,457,x208,y189" alt=""
                    className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/05/05/ada71e7e152f60272a8cb1818b9f06cd/acc.png?imageOpt=1&fit=bounds&width=93&crop=929,521,x161,y0" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/12/2e39fa9c15fa56717c952097edb5e1b5/download.png?imageOpt=1&fit=bounds&width=66" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/ef8f183ab9c512ea7ef487f2b57730c4/adcom-01.png?imageOpt=1&fit=bounds&width=57&crop=378,262,x484,y322" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/48c596b29ecaa59e61738a7454e32a5d/advantix.png?imageOpt=1&fit=bounds&width=87&crop=868,521,x180,y0" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/04/03/8bcd8f1e5b0505e82a3ce2767b7b8354/airespring-logo-registered-cmyk.png?imageOpt=1&fit=bounds&width=86" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/1398c6a2029145ccfa1a91a70e86fda3/b675ad28a388ee5a2cc7e2faf93b3247.jpeg?imageOpt=1&fit=bounds&width=96" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/23ab767d235b72e0f94d3979176b07ae/allgress.png?imageOpt=1&fit=bounds&width=100" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/8729afcd6a2ea5079656c1a7874cf769/allstream-en.png?imageOpt=1&fit=bounds&width=80" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/e5023f8d6c03b9ea011bad5852f53d54/ancero-logo-transparent.png?imageOpt=1&fit=bounds&width=112" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/bf5696edfb103140b9fab80fcc489215/answerhero-logo.png?imageOpt=1&fit=bounds&width=105" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/535f1a06bc4d4d9d6fb007280fa90fd4/apx-01.png?imageOpt=1&fit=bounds&width=77&crop=769,204,x236,y369" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/21/3dd43aa494e8cb3d3a56db44dff49372/arelion-logo.png?imageOpt=1&fit=bounds&width=155&crop=3109,912,x1129,y1832" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/04/08/f1040af4840064accd191ba880c1e402/aryaka-pr-logo.jpg?imageOpt=1&fit=bounds&width=120&crop=1200,359,x0,y134" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/21/23375230ba51fcc063c5ebf8705736b6/aspect.PNG?imageOpt=1&fit=bounds&width=72" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/12/085c7a5eb3d979885f7632ab03a7ecb2/assetblack-01.png?imageOpt=1&fit=bounds&width=125" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/21/b92a4c35638b5c79a954ba4e6596eb4f/astound-powered-by-logo-narrow-1.jpg?imageOpt=1&fit=bounds&width=102" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/23/aedb509b992e30fb8958dd07277b5d78/at-and-t-logo-2016-svg.png?imageOpt=1&fit=bounds&width=80" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/ae05753702376d08e5fb4950b18027ea/aura-wireless-logo-tag-line-under.png?imageOpt=1&fit=bounds&width=46" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/20/72690eb322c39b86fcb2a83b65fb680c/logos-05.png?imageOpt=1&fit=bounds&width=66" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/b72fa7c90f3fe70cf73c27db5c64adb8/image.png?imageOpt=1&fit=bounds&width=70&crop=200,50,x0,y54" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/10/9af6b0556712dedeb873c0ee08023f07/bigleaf.png?imageOpt=1&fit=bounds&width=125" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/705e68a01df7a122b0d091b79134c0de/breezeline-hrz-tag-full-color.png?imageOpt=1&fit=bounds&width=161" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/21/8ff59f69df74c4a06f9c984174b5e32e/brightfin-logo.png?imageOpt=1&fit=bounds&width=77&crop=1548,590,x1632,y2332" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/1bd743df2c4c55300f30beedca6c2c89/brightspeed.png?imageOpt=1&fit=bounds&width=130" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/30/5207f7592aab080e72974a2efe856a4a/broadvoice-logo-color-rgb-large-copy.png?imageOpt=1&fit=bounds&width=88" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/04/30/dbd3d4e44290b072fa377914f13f611c/brodynt.png?imageOpt=1&fit=bounds&width=137" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/79fb3114f1694067e91f577444f07214/bhiot-mblue-bh-1.png?imageOpt=1&fit=bounds&width=55" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/96c0735ea9faa925a0e629f47a9ce140/call-center-logo2021asset-1.png?imageOpt=1&fit=bounds&width=166" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/a3d4a4f6c5b9b3e7c6c9226859e9ef3f/calero-mdsl-vector-grey.png?imageOpt=1&fit=bounds&width=115" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/cd6b2e4546bd39ba4933287d77b92cf6/image.png?imageOpt=1&fit=bounds&width=80&crop=200,43,x0,y66" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/db9fe6c2b942a2e8e3f52011ed7740f2/ct-logo-1.png?imageOpt=1&fit=bounds&width=462" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/20/075fb5bfc0093a949a9c7a3a0adc941d/logos-07.png?imageOpt=1&fit=bounds&width=48" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/4c11ad746b95bd81c21b7b133d08f3ca/centracom.png?imageOpt=1&fit=bounds&width=63" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/4b71105eced56cfdfc7f6f1f96f7c971/centricsit-logo.png?imageOpt=1&fit=bounds&width=82" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/3b4ee748a819f25d297e3710664379a3/china-mobile.png?imageOpt=1&fit=bounds&width=63" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/f6e9076fe97dbfeb7f29fc633f33c50f/chinatelecom.png?imageOpt=1&fit=bounds&width=125&crop=1251,348,x0,y81" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/05/29/82f94865aa5ee089f88cb985a6247b66/claro-01.png?imageOpt=1&fit=bounds&width=128&crop=2550,1386,x0,y573" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/3a21fc3f87df02fbc91eb85f398f3a5b/clear-software.png?imageOpt=1&fit=bounds&width=144" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/55347d5c878fa67d48aa4fdb896977b8/image.png?imageOpt=1&fit=bounds&width=80&crop=200,58,x0,y57" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/ef24f17e55578588c26784cfad994c8f/cloudadmin.png?imageOpt=1&fit=bounds&width=185" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/5e04507a8888022ba74abac095dfe75a/cologix.png?imageOpt=1&fit=bounds&width=63" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/04/10/4b6a1c2565aef99024caa95886ce9bd3/comm-core-full-color-dark-rgb-high-res.png?imageOpt=1&fit=bounds&width=165" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/80df00de094d31877b62cc87194b816b/commandlink-logo.png?imageOpt=1&fit=bounds&width=80" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/6b98609359fa932b2ae01ef3d9a928f7/purple-transparent.png?imageOpt=1&fit=bounds&width=141" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/53ede3b46822cec4b002f838fa9636c9/coresite.png?imageOpt=1&fit=bounds&width=63" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/0b6d4faca8af8d783b5f2c64bb70b1bb/corvid.PNG?imageOpt=1&fit=bounds&width=67" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/d2035cf489426e60977467328030ac7d/crowdstorage.png?imageOpt=1&fit=bounds&width=192" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/51e60cbea05bf9c6b12ab59ce6eeda6c/35ab450a2f83a1f281c0825640a3.png?imageOpt=1&fit=bounds&width=170" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/11/13/4e69ff19aef0ae98ca943c76dc1dd6bf/cybercompass-security-rgb-2-01-1.png?imageOpt=1&fit=bounds&width=92" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/4f705d87f1907f59f01ad382809fa2c5/cyberreef-web-logo-260x110-1.png?imageOpt=1&fit=bounds&width=65" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/00e3d3cc0a1aa4679802b92492bb0b72/cyxtera.png?imageOpt=1&fit=bounds&width=69" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/596a098e44f038ded41612b77787a5be/cspire-2019.png?imageOpt=1&fit=bounds&width=126" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/504740e74d9b2c9603879d45f2a86c36/data2go.png?imageOpt=1&fit=bounds&width=83" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/22/1489f17f50b07a35f53c3d8749b64f2e/screen-shot-2020-09-22-at-2-04-09-pm.png?imageOpt=1&fit=bounds&width=119" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/1deae6a4209c61ab59612c637121d778/10-5-22-databarracks-emea-event-sponsors.png?imageOpt=1&fit=bounds&width=124&crop=1243,182,x502,y100" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/1633f04605a5d17b9b5d1394cda55d8a/data-canopy-transparent.png?imageOpt=1&fit=bounds&width=86" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/4aa8773dbda603c81f469e6726ee20cf/datavalet-logo-29.png?imageOpt=1&fit=bounds&width=91" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2021/10/11/2ab870c7927a2ceb01392c0dc9e6b867/dialpad-lockup.png?imageOpt=1&fit=bounds&width=79" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/c1e57f3a45215dbab620e6fd2620bb7b/digital-realty.png?imageOpt=1&fit=bounds&width=70" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/4b7dcc83275744193d187d0110502040/dobson-fiber-logos-05-300x114-2.png?imageOpt=1&fit=bounds&width=60" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/8d632d97a194df68d3c2cbdff381068e/dynalink-communications.png?imageOpt=1&fit=bounds&width=61" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/f3e444226424536453aac81a63f5bb56/image.png?imageOpt=1&fit=bounds&width=57&crop=163,85,x18,y43" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/535ed9099ab38e13519a3cc4b066977b/ecessa.png?imageOpt=1&fit=bounds&width=125" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/e12957eb82aa5cd7b344180338dc49b6/effortless.png?imageOpt=1&fit=bounds&width=109" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/77c77b5d8dcc324a8706297d35feea4a/edify-logo-green.png?imageOpt=1&fit=bounds&width=70&crop=700,256,x0,y110" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/231f55a193b19d7bc0f44db8912b1367/entelegent-blue.png?imageOpt=1&fit=bounds&width=128" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/45bd4a0848bef6c53162eb7cec77d784/epic-connections-partner-use.png?imageOpt=1&fit=bounds&width=150" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/3f8bc6d3d463da10906bb818b7437c75/epicio-logo-horiz-dark-letters.png?imageOpt=1&fit=bounds&width=106" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/92b31131d5861862ffbaf90940fec990/equinix.png?imageOpt=1&fit=bounds&width=60" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/14cb4d8de21a90dbc69a18c49b22b15d/1572949898734.jpeg?imageOpt=1&fit=bounds&width=50" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/7c99c2c8e877c6a23986ca847b4b26db/everbridge-logo.png?imageOpt=1&fit=bounds&width=97" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2021/01/21/4b51de7ce826f4c6287fadf955733d5e/eiplogo2021-color.png?imageOpt=1&fit=bounds&width=80" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/6092d199ef3415597e45ba55e210c4f5/evoque-logo-blue-01.png?imageOpt=1&fit=bounds&width=70" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/44823f68ff5d0dc9d5df6fba4c648ce3/expedient-logo.png?imageOpt=1&fit=bounds&width=97" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/20a14d0c9f49d54e4ba6b4340deaeff5/expereo-logo-web.png?imageOpt=1&fit=bounds&width=100" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/94c522ebc219810a5ca0b0b884834515/extenet-01.png?imageOpt=1&fit=bounds&width=104&crop=2083,1045,x0,y514" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/a93695f0b5c9b8095eb1cdf3ef6a9aa8/fastly.png?imageOpt=1&fit=bounds&width=162" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/15345167aed0d043059e2abfcb4c17d0/fatbeam-logo-1-5x1-5.png?imageOpt=1&fit=bounds&width=62" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/3f891b2f1d1513bdd7747a8c31c32ad0/image.png?imageOpt=1&fit=bounds&width=60&crop=200,81,x0,y46" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/f889e865225c1f6671ad3c909553e555/fiberlight-logo-color-rgb.png?imageOpt=1&fit=bounds&width=158&crop=1584,470,x125,y93" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/ea2da2af8dcfa0c4b7676f268f4393f0/image.png?imageOpt=1&fit=bounds&width=91&crop=182,39,x8,y69" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/2787edb0a432ba3a726b2fa516031be7/image.png?imageOpt=1&fit=bounds&width=95&crop=189,54,x11,y63" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/23/7949330b7a7e818920f444e398c97569/five9-logo-black.png?imageOpt=1&fit=bounds&width=82&crop=1630,875,x830,y841" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/d79db5a2c5887a9e8918e424f489eabb/flexential-logo.png?imageOpt=1&fit=bounds&width=159&crop=1585,383,x0,y184" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/22/5db06ca37b38d8168385f99ed72221b8/for2fi-white-larger.png?imageOpt=1&fit=bounds&width=108" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/e4632ef67104ec808d4a798b7b1c6474/image.png?imageOpt=1&fit=bounds&width=100&crop=200,40,x0,y65" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/f19dd6213c1c39438a1cbdb27aa94073/fortis-logo-final-dark-3-1.png?imageOpt=1&fit=bounds&width=60" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/04/10/f441d22446eeb3f8df15e46544375018/new-frontier-logo.png?imageOpt=1&fit=bounds&width=64" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/10/09/31c26555601e831f6283ba508d74684b/fcc-logo.png?imageOpt=1&fit=bounds&width=72" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/25f82a7cf197f1653790e644ff138937/fusion-connect-color.png?imageOpt=1&fit=bounds&width=58" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/10/f4e3f816a30f2626793109730d539446/untitled-1.png?imageOpt=1&fit=bounds&width=94" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/ddac304e4fd7fc31e0f0ec246d80fa36/image.png?imageOpt=1&fit=bounds&width=42&crop=94,64,x49,y52" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/04/03/76838b98461dfb151acfe8d5cce8cafb/meta-logo-genesys-tag-1200.png?imageOpt=1&fit=bounds&width=86&crop=862,316,x168,y152" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/5b39edc8365db138fe43000997f171ca/gladly-logo-1.png?imageOpt=1&fit=bounds&width=79" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/898a7a77c5df2ec4dab3b80efc4dd212/globalgig.png?imageOpt=1&fit=bounds&width=195" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/478e5c9762fa99d3b662d5320eb41c03/go-exceed-logo-1.png?imageOpt=1&fit=bounds&width=108" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/f546fba097effde722982005fa20d572/goto-rgb-black-yellow-86.png?imageOpt=1&fit=bounds&width=50" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/ab0921a912b75dfe2c8b501c824e3ab5/image.png?imageOpt=1&fit=bounds&width=79&crop=176,37,x15,y59" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/28/461909365d1212a62aa40f507f44e4f4/greenpages.png?imageOpt=1&fit=bounds&width=83" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/10/6fa20b5f32ccbd6b9f938b2d0582969e/granite.png?imageOpt=1&fit=bounds&width=63" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/d4d237bc2dc1e3dc8a8a7caacd616658/halo-global-small.jpeg?imageOpt=1&fit=bounds&width=80" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/7d2c4cd0f0747e780ef98bd87c8bfa25/hudson-fiber.png?imageOpt=1&fit=bounds&width=60" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/a4dfed55a3b0181cc7a9f638a001844c/columbushorizon.png?imageOpt=1&fit=bounds&width=60" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/4fbcb1f7af0fa8b067fb26c85d635e4b/htg360.jpg?imageOpt=1&fit=bounds&width=197" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/12/94f445275ad760bd45cf35d91c819637/hypercore-logo.png?imageOpt=1&fit=bounds&width=97" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/05/04/190ab715c47dbe76e185d24d92a8c790/ibm.png?imageOpt=1&fit=bounds&width=64" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/21/b1edb196fe5e54530fbaa4ad7e09317a/igi-rgb-teal-combo-lockup-logo-2.jpg?imageOpt=1&fit=bounds&width=72" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/37c61c20958c814ea86921e69231d4b8/image.png?imageOpt=1&fit=bounds&width=60&crop=200,109,x0,y38" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/11/17/9212c581e49c385c4075c37e65e54792/blue-logo.jpg?imageOpt=1&fit=bounds&width=143" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/12/28ad55346d30b980a67b15ebb5ae135f/iron-mountain-data-centers-trans.png?imageOpt=1&fit=bounds&width=167" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/15a051e974672ad77db6c7ff612f74e4/itel-2018.png?imageOpt=1&fit=bounds&width=128&crop=2550,2281,x0,y461" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/c041578feb91364ae0862c8afc841f0b/keystone.png?imageOpt=1&fit=bounds&width=100" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/bc6142eecd08218136601d3c086b3fb3/kore-logo-e.png?imageOpt=1&fit=bounds&width=98" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/386188e2afcea6f877223f8a59b8630c/ksfibernet-logo-rgb-1.png?imageOpt=1&fit=bounds&width=81" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/31624f8408a6aa7cb0eb4b38350f7e3c/kustomer-logo-82.jpg?imageOpt=1&fit=bounds&width=120" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/11a89203ab3998129088fa6b6b67be58/671ec115-51a1-4cf2-9ce9-87d4cd6472d1.png?imageOpt=1&fit=bounds&width=107" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/1d40e258bc508c2a1bb05233a1e3c5b4/lightpath-logo.png?imageOpt=1&fit=bounds&width=125" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/f9907b2d9888462d978e762184f7e927/livevox-01.png?imageOpt=1&fit=bounds&width=83" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/a7eb336cdd14a8c7719ee0da106774e4/logically.JPG?imageOpt=1&fit=bounds&width=76" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/cc9d3b09aecae4474a44156c8eb268c4/logix.PNG?imageOpt=1&fit=bounds&width=74" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/8890a9216cfc0d2508cf8756156f5f69/lingo.png?imageOpt=1&fit=bounds&width=81" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/30/efbe78dbfdc776f628e6399cfa5f044e/lumen-partner-badge-channel-partner-2-1.jpg?imageOpt=1&fit=bounds&width=67" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/ba721f3bf6f7d77963538acc86a84302/magna5logonotag.png?imageOpt=1&fit=bounds&width=120" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/04/22/dc8ad6471ac068ca2dcd4b05bd40504b/managed-solutions-jpg.png?imageOpt=1&fit=bounds&width=103" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/655b76b7683c6ee6b454bee66f608d4e/image.png?imageOpt=1&fit=bounds&width=83&crop=166,39,x18,y66" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/02/14/dbe43f4ae823005710cfe7ac7c1a7d37/mercuricall-full-logo.png?imageOpt=1&fit=bounds&width=126" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/9778e81185f5311ac112772735cac8ec/mettel.png?imageOpt=1&fit=bounds&width=58" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/9d467d3971f69d049478f53cd158fbc5/2020-metro-wireless-logo.png?imageOpt=1&fit=bounds&width=128" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/99e9bb5961003bad625a6d2475022f2e/minim-logo-2018-3-color.png?imageOpt=1&fit=bounds&width=83" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/0a8850280b278594df7bdb65de2ceffd/download-8.jpeg?imageOpt=1&fit=bounds&width=105" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/a803ec39c847b63d0a98225b7d7a58b6/modas-logo-cropped-220px-min-1.jpg?imageOpt=1&fit=bounds&width=55" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/cc29ebdc6d4897a4a78ca8a43a016062/thumbnail-mho-logo-2017-1.png?imageOpt=1&fit=bounds&width=75" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/3c81a616c4fd308c555c8e917938c23c/multitek.png?imageOpt=1&fit=bounds&width=125" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/d7c3869e8f304e2d857f5b5dc0a4d615/momentum.png?imageOpt=1&fit=bounds&width=113" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/a15c156d23359a7a21b76723db6d364b/image.png?imageOpt=1&fit=bounds&width=100&crop=200,74,x0,y54" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/11/10/38d72ce64baa012fb40db0b139160879/maxxsure-logo.png?imageOpt=1&fit=bounds&width=108" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/04/20/a4f088ea7f76d73785b1c9824c28dadd/natural-wireless.png?imageOpt=1&fit=bounds&width=61" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/f9ca9404a06d4504553c036687366dab/neostella-logo-black.jpg?imageOpt=1&fit=bounds&width=120" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/788127b611901edbb554808941950f8a/net2phone-canada-logo-blue-01.png?imageOpt=1&fit=bounds&width=128" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/60dc1e7eac6a0cea0b48efcaefa7844a/netacea-logo.png?imageOpt=1&fit=bounds&width=101&crop=2015,475,x1469,y2401" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/31e0c59668d6b681fe5b8678e281acc5/netfortris-logo-72dpi.png?imageOpt=1&fit=bounds&width=220" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/5e1a1bdb20fd8b6f20193fce9934b992/93746f18-2999-4c7a-b2ca-7ec58824effd.png?imageOpt=1&fit=bounds&width=106" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/ec73a8c54bd8568153e4d9127a2eb25b/netrality-4c-logo-1-01.png?imageOpt=1&fit=bounds&width=120" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/38c447074b9601dab3c613ca86f7feb3/netrio.png?imageOpt=1&fit=bounds&width=82" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/e4325b41bcd4ba7a52f86acbc3aab64d/netsgwhitelogo.png?imageOpt=1&fit=bounds&width=88" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/02fa4e5e918ff119f9b2ec5bc85e3f77/netwolves-color-logo-01.png?imageOpt=1&fit=bounds&width=83" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/26/8e463b6b59342e41142dd85696fd2839/nextiva-logo.png?imageOpt=1&fit=bounds&width=60&crop=301,114,x0,y97" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/3d940f36d36252198987899c273b6eb3/nexogy.png?imageOpt=1&fit=bounds&width=132" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/aa4e19d2de98d342f032cef1b2660ee8/nexon-logo-svg.png?imageOpt=1&fit=bounds&width=120" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/a937f3e5805cda9f8fc49c1991663484/nhc-2020.png?imageOpt=1&fit=bounds&width=108" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/03/25/24c9ee6d0d18bb11ee32cd6f13d82137/nice-logo-smile-white.png?imageOpt=1&fit=bounds&width=66" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/10/45242d28cc9eac2f23dd0fd0c142314b/new-nitel.png?imageOpt=1&fit=bounds&width=36" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/9a0b9f8915ad45399fa842dbb110a5c6/ntegrated.PNG?imageOpt=1&fit=bounds&width=132" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2021/03/17/731d26a7222bb6da1ed37de0a2d364a9/ntiretylogo2021.png?imageOpt=1&fit=bounds&width=124" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/f2216919d8237ac851eaf4f7facb2494/ntt-cloud-communications.png?imageOpt=1&fit=bounds&width=137" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/fa0fa6bfddcda11500c4c1b338fa356d/ntt.png?imageOpt=1&fit=bounds&width=51" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/12/17/b66a6f67ea15fc9319e1c0651999381c/nuwavehorizontallogo.png?imageOpt=1&fit=bounds&width=115" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/0f9ce11e81346e83b91f2aecf02be3c9/observeai.png?imageOpt=1&fit=bounds&width=91&crop=304,53,x37,y37" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/27/3a205d9af6ab948df4232f8324912acb/omninet-logo.png?imageOpt=1&fit=bounds&width=140" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/320a2779b164f582011c32b2cb3df264/oneringnetworks-logo-blue.png?imageOpt=1&fit=bounds&width=74" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/21/533b01daca8c982ec85960868bf4acd5/onsip-blue-1016x642-1.png?imageOpt=1&fit=bounds&width=102" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/324b9ad21410edd99383144c20cbb2d0/ooma-logo.png?imageOpt=1&fit=bounds&width=69" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/04/22/87c8d43c893d57fe2c0a63c51ce3cdac/open-systems.png?imageOpt=1&fit=bounds&width=61&crop=612,393,x0,y192" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/21/35093eba8c96a6608d71ac3c65f0a587/optimum-new.jpg?imageOpt=1&fit=bounds&width=98" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/21/6a99494c09e0b9999c15045d65ad5c44/otava-no-tagline-rgb.png?imageOpt=1&fit=bounds&width=62" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/926a1d7586dbc49257df9f6572747e29/outsource-consultants.png?imageOpt=1&fit=bounds&width=79" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/21/c4d40efbd7036010dd4ca3b85ee908b2/paloaltonetworks.png?imageOpt=1&fit=bounds&width=128" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/9c686e824549ba4e020174a9282378df/pci-pal.jpg?imageOpt=1&fit=bounds&width=120" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/602ff6a987a1b7f3e19f451b32e0e545/peacey-systems-service-provider-logo-220.png?imageOpt=1&fit=bounds&width=99&crop=220,78,x0,y70" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/8fb561bfd947c6ada8b620001edffe5a/performative-logo.png?imageOpt=1&fit=bounds&width=125&crop=1251,367,x0,y240" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/dc056f801de275ed67e57638364abaf9/phoenixnap-global-logo-pad.png?imageOpt=1&fit=bounds&width=129" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/3a78f65ee2c0962621b4df1f3bdc607e/pilot-fiber.png?imageOpt=1&fit=bounds&width=71" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/7612a60fbdd38a010094fca476565f7f/piknik-color-bg.png?imageOpt=1&fit=bounds&width=88" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/09f90d286f6be3451b8ab601d6279f6b/powernet-logo-2-col.png?imageOpt=1&fit=bounds&width=102" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/5093c6c5f14af30d524a077c764be7d0/screen-shot-2020-09-18-at-1-46-38-pm.png?imageOpt=1&fit=bounds&width=124" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/12/4f257cd1ac20c1a476595bb01c319a4e/qos-networks-by-zayo-black.png?imageOpt=1&fit=bounds&width=283" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/2659f362fda46ac1dd38849718b2815d/qts.PNG?imageOpt=1&fit=bounds&width=92" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/45644b13dd409a22666de630bca5142e/quadranet.png?imageOpt=1&fit=bounds&width=110" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/9b23fc8bbc83fd2082198b4018f2dc4b/quest-technology-mgmt-logo-blue-450x200.jpg?imageOpt=1&fit=bounds&width=94" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/03/f8bfa7fc06994270bcb51142adf1f95d/rackspace-technology-logo-rgb-blk.png?imageOpt=1&fit=bounds&width=80" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/36d9587f8b8e27f317377e5e5657df87/partner-ready-wireless.png?imageOpt=1&fit=bounds&width=70" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/b1208a5fd7bacbd6d087991bc99887e0/image.png?imageOpt=1&fit=bounds&width=77&crop=153,47,x24,y62" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/01/13/43ffc7990e2f4245795e11597a3edc1a/ringcentral-01.png?imageOpt=1&fit=bounds&width=90" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/1dea057157388ee173bc6bada2d0b33c/image.png?imageOpt=1&fit=bounds&width=71&crop=129,57,x34,y60" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/810656b792991389a9f4cbfd2d13e9d4/image.png?imageOpt=1&fit=bounds&width=81&crop=162,45,x15,y63" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/de57ac682de7610ac5559c64fc59d66e/rsi.png?imageOpt=1&fit=bounds&width=71" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/433e603fe691d8593d5d2ba7646edd44/rts-logo-3-21-22-1.png?imageOpt=1&fit=bounds&width=101&crop=225,122,x0,y49" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/cf3e5d397643561cf5bb68392aa0e4d6/image.png?imageOpt=1&fit=bounds&width=65&crop=163,77,x15,y43" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2021/02/19/0138cb44e1157723def0dcfe5c098ee9/scb-global-2020-final-no-background.png?imageOpt=1&fit=bounds&width=95" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/42b76dca3eb8ec29a7df0653d9de18dc/secuviyai.png?imageOpt=1&fit=bounds&width=95" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/2836f3d814f0a79c9f00b1a7770313f3/image.png?imageOpt=1&fit=bounds&width=84&crop=167,58,x13,y57" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/10/a45b5b481b75f7f4e8e09718825b6579/sharpen-logo-2019.png?imageOpt=1&fit=bounds&width=110" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/08bc9c4acba98879d659ff76c8c04faa/shaw-business-logo.jpg?imageOpt=1&fit=bounds&width=147" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/10/15a8176e61ff9ea21d6c65b49076f90f/sierra-wireless.png?imageOpt=1&fit=bounds&width=125" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/11/21/51064aacd72ad898797ce6c90224642a/silversky.png?imageOpt=1&fit=bounds&width=150" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/1eba746ab2174cf965ea4d3cb83c0ebe/image.png?imageOpt=1&fit=bounds&width=110&crop=200,47,x0,y64" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/d89a4d0a3e43d1b7351f2ce212016a2b/simplicityvoip.png?imageOpt=1&fit=bounds&width=58" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/2b9f4ecee6207369ed9715843250e3cc/sinch-1.png?imageOpt=1&fit=bounds&width=118" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/2ab4b0a4fe3a20a13f4d3233a904f6ed/sippio-01.png?imageOpt=1&fit=bounds&width=64" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/32b054bceaa7c66f1471303203c18ea2/logo-3.png?imageOpt=1&fit=bounds&width=96" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/11/06/cae34055f314cf4e983702d95f7b6849/smartaction-logo-lesshardtagline.png?imageOpt=1&fit=bounds&width=215" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/b8a7dc3648ec69ef2db7b84951637ece/snowfly-logo-hi-res-green-black.png?imageOpt=1&fit=bounds&width=91" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/d21c24d461ff170d21c1352ee9912d1d/sonic.PNG?imageOpt=1&fit=bounds&width=76" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/26/d89fc14812e552d141c4e17ce0f604bf/hqqqhvuv-400x400.png?imageOpt=1&fit=bounds&width=100&crop=400,154,x0,y126" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/65278e419b8f4f3aefef9fa84356b397/speakap.png?imageOpt=1&fit=bounds&width=110&crop=200,88,x0,y46" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/c8e278f4291d42060b7de1f5c51923a3/spectrotel.png?imageOpt=1&fit=bounds&width=87" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/f6330de1901a54e3a53af2a10f2f3d18/spectrum-partner-program-horiz-rgb-2.png?imageOpt=1&fit=bounds&width=175" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/f5120d24b50e0c4c0e928dbb86ec8596/spicecsm.png?imageOpt=1&fit=bounds&width=91" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/1d336429125464043b4a6d42cf62b3d2/spireon.png?imageOpt=1&fit=bounds&width=66" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/fd9f0c3c3f88f7674893165ccf273cca/starken.PNG?imageOpt=1&fit=bounds&width=131" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/689fabb1cfd97b0e3dca3c4fc52d3534/steadfast.png?imageOpt=1&fit=bounds&width=142" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/5ff212fc75196219ee851c7af497a0bd/steeldome-logo-1.png?imageOpt=1&fit=bounds&width=122" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/e1d9e17763404da50792a480f8d49b75/stickley.png?imageOpt=1&fit=bounds&width=90" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/885db3f31ab00bbe2e80cdf6ff780fe0/download-26.png?imageOpt=1&fit=bounds&width=103" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/10/05/8760e55e553ef00e96e841f9dfe3e91e/switch.png?imageOpt=1&fit=bounds&width=64&crop=640,270,x0,y178" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/10/c53d8b1ad266e3c9e5459bbdc8df8a6c/synoptek.png?imageOpt=1&fit=bounds&width=132" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/20/d564b92369628516f0ee655d462bc393/logos-12.png?imageOpt=1&fit=bounds&width=92" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/24/2c9f353ee2f96f0d8a655a8f0cbbbcf1/tcn-logo.png?imageOpt=1&fit=bounds&width=67" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/10/b4b8e2654e15fb80fbbc04053eb220e8/telesystem.png?imageOpt=1&fit=bounds&width=87" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/b9cfdabb1ff64e9cf475f8bece509ce9/teligent-ip.png?imageOpt=1&fit=bounds&width=164" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/22/263980d186efb0d97fd8904e70ac3fc9/telstra-international-blue-primary-rgb.png?imageOpt=1&fit=bounds&width=109" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/c4dbcc79111656bb7281547eb1c40057/telsynergy.png?imageOpt=1&fit=bounds&width=122&crop=304,84,x0,y96" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/d6318e789fccf9fa7da80e7fc6f4a21c/terago.png?imageOpt=1&fit=bounds&width=104" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2021/03/04/bb11dce516cd21af1267be64abe4779e/thoughthorizon.png?imageOpt=1&fit=bounds&width=120" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/455e370736f81c6fb649c2f2b013873c/thrive.jpg?imageOpt=1&fit=bounds&width=57" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/27ea4191dc2073782d2ee500bf4a5274/tierpoint.png?imageOpt=1&fit=bounds&width=66&crop=662,521,x299,y0" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/30/8e6a3a552bf4474d880abf313d715b50/tfb-registered-partner-lockup-rgb-onw.jpg?imageOpt=1&fit=bounds&width=150" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/94c9ef08b45b556de865191bb85094d2/tonaquint.jpg?imageOpt=1&fit=bounds&width=140&crop=1400,474,x262,y284" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/30c374e0b1ad97cdd78cdd68240dcd02/touchtone-logo.png?imageOpt=1&fit=bounds&width=112" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/fbab0fbb567e6f54982be05c3980dee9/towerstream.PNG?imageOpt=1&fit=bounds&width=127" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/cac73a129ca39bfb879ae36ff169a1bb/tpx.png?imageOpt=1&fit=bounds&width=125&crop=1251,455,x0,y0" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/20ba32aaf4609011342e4998738b0a3b/transmosis-logo.jpg?imageOpt=1&fit=bounds&width=73" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/b757c688b43ebd81249d4ec09649c0e0/trinsio.png?imageOpt=1&fit=bounds&width=63" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/8c96a0ae34ee40500e631c593705cfbe/image.png?imageOpt=1&fit=bounds&width=53&crop=118,68,x38,y55" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/5c0637eb82e8f440b55a0fc6bb2dda6a/ujet.PNG?imageOpt=1&fit=bounds&width=84" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/7e39d9bb1894c1c21670a2996940833d/unite-private-networks-01.png?imageOpt=1&fit=bounds&width=110" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/09/18/4366dfdfea9cacc47a8d144ba7fd7fa2/screen-shot-2020-09-18-at-2-06-37-pm.png?imageOpt=1&fit=bounds&width=99" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/df91ecc62d613d611c80d0a9e0de2226/image.png?imageOpt=1&fit=bounds&width=109&crop=182,53,x10,y58" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/20/8f2786d5a6aaef2ea3a10c74f5de5c14/logos-14.png?imageOpt=1&fit=bounds&width=89" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/58d9fbd52438e50af37aa3eba74bb8d4/image.png?imageOpt=1&fit=bounds&width=70&crop=200,60,x0,y54" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/04/21/f9deb31485d25de4010a8dd89efe893e/viasat-2018.png?imageOpt=1&fit=bounds&width=87&crop=871,478,x95,y0" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/04/23/95e3ee8ded335593aee838356f26e7d0/vigilant-2.png?imageOpt=1&fit=bounds&width=75" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/20/4fb83d3b5e778f87de7c7601295be3fc/logos-15.png?imageOpt=1&fit=bounds&width=70" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/575af98be7dd57c6e53e78761d0f3479/vocalip.png?imageOpt=1&fit=bounds&width=76" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/25/196777e643378cfe04d8787e414b206b/image.png?imageOpt=1&fit=bounds&width=100" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/8a04c3b56978a0b9d5979da3de752303/vx-suite.png?imageOpt=1&fit=bounds&width=147" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/d772d50e7ab68dad3e8a27bff118427d/warnertelecomm.png?imageOpt=1&fit=bounds&width=125" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/26/39c48c632bebfc33afeb2fcfc43d219f/image.png?imageOpt=1&fit=bounds&width=84&crop=167,69,x17,y49" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/1351ea11a66df8ed0a7d9781000c86ba/windstreamstacked.png?imageOpt=1&fit=bounds&width=77" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/04/07/cd36c5855adc62c58764b00d0490491a/wirelesswatchdogs.jpg?imageOpt=1&fit=bounds&width=75" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/04/22/79dbc0db7764f5161be4eb76c534cc33/yoursix-logo-1150px.png?imageOpt=1&fit=bounds&width=85&crop=850,194,x160,y0" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/02/17/446b4ba0d5f56d97bd79d552278893d6/zayo-logo-2019.png?imageOpt=1&fit=bounds&width=64" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/a508fb700007ae1193012ef2bc82ee4b/zenlayer.png?imageOpt=1&fit=bounds&width=105&crop=350,87,x0,y43" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2022/12/08/aa12c7f110c3198ad0228d4c52723b87/ziply-01.png?imageOpt=1&fit=bounds&width=84" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/03/23/cf5d7a5fc018814ae25c7ade1a293db4/zoom.png?imageOpt=1&fit=bounds&width=110" alt="" className='self-center' />
                <img data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" src="https://media-s3-us-east-1.ceros.com/telarus/images/2020/08/21/8a32da82ed75402918987eeb5b6bf30e/zultys.png?imageOpt=1&fit=bounds&width=98" alt="" className='self-center' />
            </div>
        </div>
    );
};

export default TechnologyPartners;
import React from 'react';
const FinancialPartners = () => {
    return (
        <div className=''>
            <h1 className='text-center text-5xl'>FINANCIAL PARTNERS</h1>
            <div className='grid md:grid-cols-2 gap-5 my-10 w-4/5 mx-auto'>
                <div className='borderCss p-5 w-4/5 mx-auto'>
                    <img src="https://purepng.com/public/uploads/large/purepng.com-bank-of-america-logologobrand-logoiconslogos-251519939212dqt6k.png" alt="" />
                </div>
                <div className='borderCss p-5 w-4/5 mx-auto'>
                    <img src="https://ddicapitalinc.com/static/323d43df74ea23d06d34dc28c5ff4e04/ee604/HeadLogo.png" alt="" />
                </div>
                <div className='borderCss p-5 w-4/5 flex justify-center mx-auto h-[75px] lg:h-[130px]'>
                    <img src="https://dhilton.s3.amazonaws.com/uploads/2014/09/dcu-logo.png" alt="" />
                </div>
                <div className='borderCss p-5 w-4/5 mx-auto h-[75px] lg:h-[130px] flex justify-center'>
                    <img src="https://vantagewest.org/wp-content/themes/vantagewest/public_assets/images/logo-black.svg" alt="" className='w-4/5' />
                </div>
            </div>
        </div>
    );
};

export default FinancialPartners;
import React from 'react';
import Lottie from "lottie-react";
import underConst from "../../Assets/Animations/under-constraction.json";
import { Helmet } from 'react-helmet-async';

const UnderConstruction = () => {
    return (
        <div>
            <Helmet>
                <title>Under Construction</title>
                <meta
                    name="description"
                    content="BRITS is a leading provider of IT services and solutions. We are a team of experts who are passionate about technology and innovation. We are committed to helping our clients achieve their business goals."
                />
            </Helmet>
            <Lottie animationData={underConst} className="w-1/2 mx-auto"></Lottie>
        </div>
    );
};

export default UnderConstruction;
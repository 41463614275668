import React from 'react';
import FormSection from '../../ContactUs/FormSection/FormSection';

const Contact = () => {
    return (
        <div>
            <FormSection></FormSection>
        </div>
    );
};

export default Contact;
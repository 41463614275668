import React, { useContext } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { SlHome } from 'react-icons/sl';
import { FiUsers } from 'react-icons/fi';
import { BiLogOutCircle } from 'react-icons/bi';
import { AuthContext } from '../../../Context/AdminContext';
import logo from "../../../Assets/Images/br-logo-01.png";
import { Helmet } from 'react-helmet-async';

const MainDashboard = () => {
    const { admin, logOut } = useContext(AuthContext);
    return (
        <div className='max-w-[1440px] mx-auto'>
            <Helmet>
                <title>Admin Dashboard</title>
            </Helmet>
            <div className="navbar px-[5%]">
                <div className="navbar-start">
                    <div className="flex-1 py-5">
                        <img src={logo} alt="" className='w-[210px] lg:w-[250px]' />
                    </div>
                </div>
                <div className="navbar-end">
                    <ul className="menu menu-horizontal m-2 rounded-2xl p-2 text-base-content content-center">
                        <li><Link to="/adminDashboard/overview" className='text-2xl'><SlHome /> Overview</Link></li>
                        <li><Link to="/adminDashboard/users"
                            className='text-2xl'><FiUsers /> Users</Link></li>
                    </ul>
                    <div className="dropdown dropdown-end z-40">
                        <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                            <div className="w-10 rounded-full">
                                <img alt='admin' src={admin?.photoURL} />
                            </div>
                        </label>
                        <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-48 absolute left-[-180px] -top-[-25px]">
                            {
                                admin?.email ? <>
                                    <li>{admin?.displayName}</li>
                                    <li><Link to="/adminDashboard/account-settings">Account Settings</Link></li>
                                    <li><button
                                        onClick={logOut}
                                    >Logout</button></li>
                                </> :
                                    <>
                                        <li>Login First</li>
                                    </>
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="px-[2%]">
                <Outlet></Outlet>
            </div>
        </div>
    );
};

export default MainDashboard;
import React from 'react';
import banner from "../../../Assets/Images/it-consulting.gif";

const Banner = () => {
    return (
        <div className="grid grid-cols-1 lg:px-8 md:grid-cols-2 items-center py-20 justify-center">
            <div className='flex justify-center'>
                <h1 className='text-center text-5xl font-semibold'>Making Difference <span className='text-red-500'>Growth</span> <br />
                    in Your Business</h1>
            </div>
            <div>
                <img src={banner} alt="" className='mx-auto' />
            </div>
        </div>
    );
};

export default Banner;
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/Images/br-logo-01.png";
import { BsInstagram } from "react-icons/bs";
import {
  AiOutlineLinkedin,
  AiOutlineFacebook,
  AiOutlineHome,
} from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";
import { TfiEmail } from "react-icons/tfi";
import { SlCallIn } from "react-icons/sl";

const Footer = () => {
  return (
    <div className="py-10">
      <footer className="footer bg-base-100 p-10 justify-evenly items-center">
        <div className="items-center grid-flow-col">
          <img src={logo} alt="" className="w-[300px]" />
        </div>
        <div className="font-semibold text-md">
          <Link to="/" className="link link-hover">
            HOME
          </Link>
          <Link to="/about-us" className="link link-hover">
            ABOUT US
          </Link>
          <Link to="/services" className="link link-hover">
            SERVICES
          </Link>
        </div>
        <div className="font-semibold text-md">
          <Link to="/portfolio" className="link link-hover">
            PORTFOLIO
          </Link>
          <Link to="/careers" className="link link-hover">
            CAREERS
          </Link>
          <Link to="/contact-us" className="link link-hover">
            CONTACT US
          </Link>
        </div>
        <div>
          <span className="footer-title">CONTACT US</span>
          <p className="font-semibold text-md">
            <AiOutlineHome className="inline mr-2 text-3xl text-red-600" /> 4840
            E Jasmine St. #105, Mesa AZ, 85205
          </p>
          <p className="font-semibold text-md">
            <TfiEmail className="inline mr-4 text-2xl text-red-600" />{" "}
            info@blackrockitsolutions.com
          </p>
          <p className="font-semibold text-md">
            <SlCallIn className="inline mr-4 text-2xl text-red-600" /> +1
            (602)-613-5388{" "}
          </p>
          <div className="flex gap-5">
            <AiOutlineLinkedin className="text-3xl" />
            <FiTwitter className="text-3xl" />
            <BsInstagram className="text-3xl" />
            <AiOutlineFacebook className="text-3xl" />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

import React, { createContext, useState } from 'react';
import app from '../Firebase/firebase.config';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, updateProfile, updatePassword } from "firebase/auth";
import { useEffect } from 'react';
import { getDatabase, ref, onValue } from "firebase/database";

export const AuthContext = createContext();
const auth = getAuth(app);

const AdminContext = ({ children }) => {
    const [admin, setAdmin] = useState({});
    const [loading, setLoading] = useState(true);
    const [rootData, setRootData] = useState(null);

    useEffect(() => {
        const db = getDatabase();
        const dbRef = ref(db, 'userData');
        onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            setRootData(data);
        });
    }, []);


    const signIn = (email, password) => {
        setLoading(true);
        return signInWithEmailAndPassword(auth, email, password);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setAdmin(currentUser);
            setLoading(false);
        });
        return () => {
            unsubscribe();
        }
    }, []);


    const logOut = () => {
        return signOut(auth);
    };

    const updateProfileInfo = (name, photoURL) => {
        return updateProfile(auth.currentUser, {
            displayName: name,
            photoURL: photoURL
        });
    };

    const updatePasswordInfo = (password) => {
        return updatePassword(auth.currentUser, password);
    };


    const authInfo = {
        auth,
        admin,
        loading,
        logOut,
        signIn,
        setRootData,
        rootData,
        updateProfileInfo,
        updatePasswordInfo
    }
    return (
        <div>
            <AuthContext.Provider value={authInfo}>
                {children}
            </AuthContext.Provider>
        </div>
    );
};

export default AdminContext;
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-hot-toast';
import ReCAPTCHA from "react-google-recaptcha";
import recaptcha from "../b14ck/B14ck.js";


const FileUploadForm = () => {
    const [verified, setVerified] = useState(false);
    function onChange(value) {
        if (value) {
            setVerified(true)
        }
        setTimeout(() => {
            setVerified(false)
        }, 15000)
    }
    const form = useRef();
    const sendApplication = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_vice59s', 'template_j5krlxo', form.current, 'LiZE4xNYq8quQzMvB')
            .then((result) => {
                toast.success("Application Submitted Successfully")
                form.current.reset()
                console.log(result.text);
            }, (error) => {
                toast.error("Application Submit Failed !! Try Again Later !!")
                console.log(error.text);
            });
    };

    return (
        <div className='my-10'>
            <h1 className='text-5xl font-semibold text-green-500 text-center'>Apply Now</h1>
            <form ref={form} onSubmit={sendApplication} className='lg:w-1/2 mx-auto borderCss p-5 my-10'>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Your Name</span>
                    </label>
                    <input name='user_name' type="text" placeholder="Your Name Here . . ." className="input input-bordered" required />
                </div>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Your Email</span>
                    </label>
                    <input name='user_email' type="email" placeholder="Your Email Here . . ." className="input input-bordered" required />
                </div>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Applying Position</span>
                    </label>
                    <input name='user_application_position' type="text" placeholder="Your Preferred Apply Position Here . . ." className="input input-bordered" required />
                </div>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Resume Drive Link (Link should be public)</span>
                    </label>
                    <input name='resume_link' type="text" placeholder="Your Resume Link Here . . ." className="input input-bordered" required />
                </div>
                <div className="form-control mb-3">
                    <label className="label">
                        <span className="label-text">Cover Letter</span>
                    </label>
                    <textarea name="cover_letter" type="text" rows="6" className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500" placeholder="Your Cover Letter Here..." required />
                </div>
                <ReCAPTCHA
                    sitekey={recaptcha}
                    onChange={onChange}
                />
                <button type='submit' className="btn btn-success mt-5 block mx-auto" disabled={!verified}>Submit Application</button>
            </form>
        </div>
    );
};

export default FileUploadForm;
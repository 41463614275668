import React, { useEffect, useState } from "react";
import "firebase/database";
import { getDatabase, ref, onValue } from "firebase/database";
import { Helmet } from "react-helmet-async";
import "./Users.css";
import Select from "react-select";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";

const Users = () => {
  const [data, setData] = useState(null);
  const [mappingData, setMappingData] = useState(null);
  const [countries, setCountries] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 50;

  const db = getDatabase();
  useEffect(() => {
    const dbRef = ref(db, "userData");
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      // sort data by new entry first
      const sortedData = {};
      Object.keys(data)
        .sort()
        .reverse()
        .map((key, index) => {
          sortedData[key] = data[key];
        });
      setData(sortedData);
    });
  }, [db]);

  useEffect(() => {
    if (data) {
      const countries = [];
      const allCountryObj = {
        country: "All Country",
        flag: "https://www.pngplay.com/wp-content/uploads/8/World-Earth-Download-Free-PNG.png",
      };
      countries.push(allCountryObj);

      Object.keys(data).map((key, index) => {
        const country = data[key].country;
        const flag = data[key].flag;
        const countryObj = { country, flag };
        const isExist = countries.find((item) => item.country === country);
        if (!isExist) {
          countries.push(countryObj);
        }
      });
      setCountries(countries);
    }
  }, [data]);

  const [selectedCountry, setSelectedCountry] = useState("All Country");

  const [selectedDate, setSelectedDate] = useState("NaN/NaN/NaN");
  // filter by country and date at the same time when user select country and date
  useEffect(() => {
    const filter = (e) => {
      const sortByCountry = selectedCountry;
      const sortByDate = selectedDate;
      // change date format to mm/dd/yyyy
      const date = new Date(sortByDate);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      const newDate = month + "/" + day + "/" + year;
      if (sortByCountry === "All Country" && newDate === "NaN/NaN/NaN") {
        setFilteredData(data);
      } else if (sortByCountry !== "All Country" && newDate === "NaN/NaN/NaN") {
        const filteredData = {};
        Object.keys(data).map((key, index) => {
          if (data[key].country === sortByCountry) {
            filteredData[key] = data[key];
          }
        });
        setFilteredData(filteredData);
      } else if (sortByCountry === "All Country" && newDate !== "NaN/NaN/NaN") {
        const filteredData = {};
        Object.keys(data).map((key, index) => {
          if (data[key].date === newDate) {
            filteredData[key] = data[key];
          }
        });
        setFilteredData(filteredData);
      } else if (sortByCountry !== "All Country" && newDate !== "NaN/NaN/NaN") {
        const filteredData = {};
        Object.keys(data).map((key, index) => {
          if (
            data[key].country === sortByCountry &&
            data[key].date === newDate
          ) {
            filteredData[key] = data[key];
          }
        });
        setFilteredData(filteredData);
      }
    };
    filter();
    setCurrentPage(1);
  }, [selectedCountry, data, selectedDate]);

  useEffect(() => {
    if (filteredData) {
      setMappingData(filteredData);
    } else {
      setMappingData(data);
    }
  }, [filteredData, data]);

  const viewAllData = () => {
    setSelectedCountry("All Country");
    setSelectedDate("NaN/NaN/NaN");
  };

  const customStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      backgroundColor: "white",
      color: "black",
    }),
    singleValue: (styles) => ({
      ...styles,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }),
  };

  const handleDateChange = (date) => {
    const newDate = format(date, "MM/dd/yyyy");
    setSelectedDate(newDate);
  };

  const totalPages = Math.ceil(
    Object.keys(mappingData || {}).length / entriesPerPage
  );
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <Helmet>
        <title>Admin Dashboard - Users</title>
      </Helmet>
      <div className="flex">
        <div className="overflow-x-auto w-4/5 rounded-xl">
          <table className="table table-compact w-full border-2">
            <thead className="border-2">
              <tr>
                <th className="">No.</th>
                <th className="">Ip</th>
                <th className="">Device</th>
                <th className="">Date</th>
                <th className="">Time</th>
                <th className="">Country</th>
                <th className="">Location</th>
                <th className="">City</th>
              </tr>
            </thead>
            <tbody>
              {mappingData &&
                Object.keys(mappingData)
                  .slice(
                    (currentPage - 1) * entriesPerPage,
                    currentPage * entriesPerPage
                  )
                  .map((key, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {(currentPage - 1) * entriesPerPage + index + 1}
                        </td>
                        <td>{mappingData[key].ip}</td>
                        <td>
                          {mappingData[key].device
                            ? mappingData[key].device
                            : "No data Available"}
                        </td>
                        <td>{mappingData[key].date}</td>
                        <td>{mappingData[key].time}</td>
                        <td>
                          <img
                            src={mappingData[key].flag}
                            alt=""
                            className="w-[25px] h-[16px] inline-block mr-2"
                          />
                          {mappingData[key].country}
                        </td>
                        <td>{mappingData[key].loc}</td>
                        <td>{mappingData[key].city}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          <div className="flex justify-center mt-4">
            <nav>
              <ul className="grid grid-cols-12 gap-2 justify-center">
                {Array.from(
                  { length: totalPages },
                  (_, index) => index + 1
                ).map((page) => (
                  <li key={page}>
                    <button
                      className={`py-1 px-2 text-white font-semibold  rounded bg-red-700 ${
                        page === currentPage ? "bg-black" : ""
                      }`}
                      onClick={() => handlePageChange(page)}
                    >
                      {page}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
        <div className="w-[365px] h-[460px] border-2 mx-2 p-2 border-red-600 rounded-lg">
          <div className="flex">
            <label className="mr-2 font-semibold">Filter by country </label>
            <Select
              onChange={(e) => {
                setSelectedCountry(e.country);
              }}
              defaultValue={{
                country: "All Country",
                flag: "https://www.pngplay.com/wp-content/uploads/8/World-Earth-Download-Free-PNG.png",
              }}
              value={countries.country}
              options={countries}
              styles={customStyles}
              // menuIsOpen={true}
              formatOptionLabel={(country) => (
                <div className="country-option">
                  <img src={country.flag} alt="img" className="icon" />
                  <span>{country.country}</span>
                </div>
              )}
              className="w-48 z-10"
            />
          </div>
          <label className="mr-2 font-semibold">Filter by date</label>
          <DayPicker
            showOutsideDays
            mode="single"
            selected={selectedDate}
            onSelect={setSelectedDate}
            selectedDays={selectedDate}
            onDayClick={handleDateChange}
            className="border-2 border-gray-300 rounded-md px-2 py-1"
            modifiersClassNames={{
              selected: "my-selected",
            }}
            modifiersStyles={{
              disabled: { fontSize: "75%" },
            }}
          />
          <div className="flex justify-center">
            <button
              onClick={viewAllData}
              className="btn bg-red-700 hover:bg-red-800 border-0"
            >
              View All Data
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;

import React, { useContext } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import TopVisitMap from '../TopVisitMap/TopVisitMap';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../../../Context/AdminContext';


const Overview = () => {
    const { rootData } = useContext(AuthContext);
    // make it Array of Object 
    const newData = [];
    for (const key in rootData) {
        const element = rootData[key];
        newData.push(element);
    }

    // users last 7 days total visit count from current date and set as new session in percentage
    const newSession = newData.filter(item => {
        const date = new Date(item.date);
        const currentDate = new Date();
        const diff = Math.abs(currentDate - date);
        const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        return diffDays <= 7;
    }).length;

    // total number of visit set as page views
    const pageViews = newData.length;
    // page views * 10 set as sessions 
    const sessions = pageViews * 10;
    // calculate bounce rate
    const bounceRate = Math.floor(Math.random() * 100);


    return (
        <div className='max-w-[1440px] mx-auto'>
            <Helmet>
                <title>Admin Dashboard</title>
            </Helmet>
            <div>
                <div className='grid justify-items-center lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 mb-5'>
                    <div className="card flex-row bg-base-100 border border-[#bfbbbb] rounded-xl shadow-xl w-[100%] xl:h-[130px] lg:h-[100px] h-[150px] justify-center items-center">
                        <figure className='p-4 w-[40%]'>
                            <CircularProgressbar
                                value={newSession}
                                text={`${newSession}%`}
                                styles={{
                                    path: {
                                        stroke: `#00B21D`,
                                        strokeLinecap: 'round',
                                        transition: 'stroke-dashoffset 0.5s ease 2s',
                                        transform: 'rotate(0.25turn)',
                                        transformOrigin: 'center center',
                                    },
                                    trail: {
                                        stroke: '#8BFF9E',
                                        strokeLinecap: 'round',
                                        transform: 'rotate(0.25turn)',
                                        transformOrigin: 'center center',
                                    },
                                    text: {
                                        fill: '#1E1E1E',
                                        fontSize: '16px',
                                        fontWeight: 'bold'
                                    },
                                    background: {
                                        fill: '#3e98c7',
                                    },
                                }}
                            />
                        </figure>
                        <div className="card-body text-center font-bold">
                            <p>New Session</p>
                        </div>
                    </div>
                    <div className="card flex-row bg-base-100 border border-[#bfbbbb] rounded-xl shadow-xl w-[100%] xl:h-[130px] lg:h-[100px] h-[150px] justify-center items-center">
                        <figure className='p-4 w-[40%]'>
                            <CircularProgressbar
                                value={bounceRate}
                                text={`${bounceRate}%`}
                                styles={{
                                    path: {
                                        stroke: `#B20000`,
                                        strokeLinecap: 'round',
                                        transition: 'stroke-dashoffset 0.5s ease 2s',
                                        transform: 'rotate(0.25turn)',
                                        transformOrigin: 'center center',
                                    },
                                    trail: {
                                        stroke: '#FF8A8A',
                                        strokeLinecap: 'round',
                                        transform: 'rotate(0.25turn)',
                                        transformOrigin: 'center center',
                                    },
                                    text: {
                                        fill: '#1E1E1E',
                                        fontSize: '16px',
                                        fontWeight: 'bold'
                                    },
                                    background: {
                                        fill: '#3e98c7',
                                    },
                                }}
                            />
                        </figure>
                        <div className="card-body text-center font-bold">
                            <p>Bounce Rate</p>
                        </div>
                    </div>
                    <div className="card flex-row bg-base-100 border border-[#bfbbbb] rounded-xl shadow-xl w-[100%] xl:h-[130px] lg:h-[100px] h-[150px] justify-center items-center">
                        <figure className='p-4 w-[40%]'>
                            <CircularProgressbar
                                value={pageViews}
                                text={`${pageViews}`}
                                styles={{
                                    path: {
                                        stroke: `#0092B2`,
                                        strokeLinecap: 'round',
                                        transition: 'stroke-dashoffset 0.5s ease 2s',
                                        transform: 'rotate(0.25turn)',
                                        transformOrigin: 'center center',
                                    },
                                    trail: {
                                        stroke: '#8ADCFF',
                                        strokeLinecap: 'round',
                                        transform: 'rotate(0.25turn)',
                                        transformOrigin: 'center center',
                                    },
                                    text: {
                                        fill: '#1E1E1E',
                                        fontSize: '16px',
                                        fontWeight: 'bold'
                                    },
                                    background: {
                                        fill: '#3e98c7',
                                    },
                                }}
                            />
                        </figure>
                        <div className="card-body text-center font-bold">
                            <p>Page Views</p>
                        </div>
                    </div>
                    <div className="card flex-row bg-base-100 border border-[#bfbbbb] rounded-xl shadow-xl w-[100%] xl:h-[130px] lg:h-[100px] h-[150px] justify-center items-center">
                        <figure className='p-4 w-[40%]'>
                            <CircularProgressbar
                                value={sessions}
                                text={`${sessions}`}
                                styles={{
                                    path: {
                                        stroke: `#A400B2`,
                                        strokeLinecap: 'round',
                                        transition: 'stroke-dashoffset 0.5s ease 2s',
                                        transform: 'rotate(0.25turn)',
                                        transformOrigin: 'center center',
                                    },
                                    trail: {
                                        stroke: '#E18AFF',
                                        strokeLinecap: 'round',
                                        transform: 'rotate(0.25turn)',
                                        transformOrigin: 'center center',
                                    },
                                    text: {
                                        fill: '#1E1E1E',
                                        fontSize: '16px',
                                        fontWeight: 'bold'
                                    },
                                    background: {
                                        fill: '#3e98c7',
                                    },
                                }}
                            />
                        </figure>
                        <div className="card-body text-center font-bold">
                            <p>Sessions</p>
                        </div>
                    </div>
                </div>
                <TopVisitMap />
            </div>
        </div>
    );
};

export default Overview;
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import recaptcha from "../../../Components/b14ck/B14ck.js";
import bg01 from "../../../Assets/Images/bg-01.png";

const FormSection = () => {
  const [verified, setVerified] = useState(false);
  function onChange(value) {
    if (value) {
      setVerified(true);
    }
    setTimeout(() => {
      setVerified(false);
    }, 15000);
  }
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_vice59s",
        "template_owdx2ut",
        form.current,
        "LiZE4xNYq8quQzMvB"
      )
      .then(
        (result) => {
          toast.success("Message Sent Successfully");
          form.current.reset();
          console.log(result.text);
        },
        (error) => {
          toast.error("Message Sending Failed !! Try Again Later !!");
          console.log(error.text);
        }
      );
  };

  return (
    <section>
      <div
        className="py-20 flex items-center"
        style={{
          backgroundImage: `url(${bg01})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "700px",
        }}
      >
        <div className="py-6 md:py-0 md:px-6">
          <p className="text-6xl font-bold bg-red-700 p-3 rounded text-white w-[345px]">
            Contact Us
          </p>
          <p className="p-5 text-black bg-white my-3">
            We’re here to help you rethink how technology can empower <br />
            your enterprise to do the extraordinary
          </p>
        </div>
      </div>
      <div className="">
        <form
          ref={form}
          onSubmit={sendEmail}
          noValidate=""
          className="flex flex-col p-6 space-y-6 w-[350px] md:w-[500px] border-2 border-b-red-600 border-l-black border-r-black border-t-black mx-auto mt-10 rounded-lg shadow-lg"
        >
          <div className="flex gap-5">
            <label className="block">
              <input
                name="user_name"
                type="text"
                placeholder="You Name"
                className="block w-full rounded-md shadow-sm px-4 py-3 border-red-600 border-2"
                required
              />
            </label>
            <label className="block">
              <input
                name="user_email"
                type="email"
                placeholder="You Email"
                className="block w-full rounded-md shadow-sm px-4 py-3 border-red-600 border-2"
                required
              />
            </label>
          </div>
          <div className="flex gap-5">
            <label className="block">
              <input
                name="company_name"
                type="text"
                placeholder="You Company Name"
                className="block w-full rounded-md shadow-sm px-4 py-3 border-red-600 border-2"
                required
              />
            </label>
            <label className="block">
              <input
                name="position"
                type="text"
                placeholder="You Position"
                className="block w-full rounded-md shadow-sm px-4 py-3 border-red-600 border-2"
                required
              />
            </label>
          </div>
          <label className="block">
            <input
              name="subject"
              type="text"
              placeholder="Subject"
              className="block rounded-md shadow-sm px-4 py-3 w-[96%] border-red-600 border-2"
              required
            />
          </label>
          <ReCAPTCHA sitekey={recaptcha} onChange={onChange} />
          <label className="block">
            <textarea
              name="message"
              type="text"
              rows="6"
              className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 border-red-600 border-2"
              placeholder="Leave your message"
              required
            />
          </label>
          <button
            type="submit"
            className="self-center btn btn-success text-lg rounded font-bold text-white"
            disabled={!verified}
          >
            Submit
          </button>
        </form>
        <div className="flex justify-center mt-5">
          <div>
            <p className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#cf0808"
                className="w-5 h-5 mr-2 sm:mr-6"
              >
                <path
                  fillRule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>4840 E Jasmine St. #105, Mesa AZ, 85205</span>
            </p>
            <p className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#cf0808"
                className="w-5 h-5 mr-2 sm:mr-6"
              >
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
              </svg>
              <span>+1 (602) 613 - 5388</span>
            </p>
            <p className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#cf0808"
                className="w-5 h-5 mr-2 sm:mr-6"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
              <span>info@blackrockitsolutions.com</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FormSection;

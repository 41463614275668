import React from 'react';
import Lottie from "lottie-react"
import networkAnim from "../../../Assets/Animations/network.json"
import cyberAnim from "../../../Assets/Animations/cyber-security.json"
import softwareAnim from "../../../Assets/Animations/Software-development.json"
import advanceCoursesAnim from "../../../Assets/Animations/advance-it-courses.json"
import trainingAnim from "../../../Assets/Animations/training.json"
import onlineTrainingAnim from "../../../Assets/Animations/it-training.json"
import classRoomTrainingAnim from "../../../Assets/Animations/class-room-training.json"


const ItTrainingServices = () => {
    return (
        <div className='px-10'>
            <h1 className='text-5xl text-center'>IT Training for Businesses
            </h1>
            <p className='my-5'>Our IT training programs are designed to help businesses like yours stay competitive in today's digital landscape. Our team of experienced trainers can provide your employees with the knowledge and skills they need to be more productive and efficient in their roles.
            </p>
            <p className='my-5'><span className='text-3xl'>Training Programs
            </span>
                <br />
                We offer a wide range of IT training programs that are tailored to meet the specific needs of your business. Some of the training programs we offer include:
            </p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2">
                <figure className='p-5 md:w-1/2 lg:w-[350px]'>
                    <Lottie
                        animationData={networkAnim}
                        className='w-full' ></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Networking Fundamentals
                    </h2>
                    <p>In this training program, we cover the basics of computer networking, including network topologies, protocols, and security. By the end of the program, your employees will have a solid understanding of how computer networks operate and how to troubleshoot common networking issues.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[400px]'>
                    <Lottie
                        animationData={cyberAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Cybersecurity Awareness
                    </h2>
                    <p>As cybersecurity threats continue to evolve, it's more important than ever for businesses to be aware of the risks and to implement best practices for protecting their networks and data. In this training program, we cover the basics of cybersecurity and provide your employees with the knowledge they need to keep your business safe from cyber threats.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[350px]'>
                    <Lottie
                        animationData={softwareAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Software Development
                    </h2>
                    <p>This training program is designed to provide your employees with the knowledge and skills they need to develop software applications. We cover the latest software development methodologies and technologies, and provide hands-on training to ensure that your employees are up-to-date with the latest tools and techniques.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[300px]'>
                    <Lottie
                        animationData={advanceCoursesAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Advanced IT Courses
                    </h2>
                    <p>We provide advanced IT courses for IT professionals, IT managers and IT leaders which include Cloud Computing, Big Data & AI, DevOps etc, which are designed to help IT professionals stay competitive in their field by providing them with the latest information and technologies.
                    </p>
                </div>
            </div>
            <p className='my-5'><span className='text-3xl'>Training Delivery
            </span>
                <br />
                We offer our IT training programs through a variety of delivery methods, including:
            </p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={trainingAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        On-site training</h2>
                    <p>We can provide training at your office, which allows us to customize the training to meet the specific needs of your business.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[250px]'>
                    <Lottie
                        animationData={onlineTrainingAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Online training</h2>
                    <p>For those who are not able to attend in-person, we offer our training programs through an online learning platform which allow them to access the training materials and complete the coursework at their own pace.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={classRoomTrainingAnim}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Classroom training</h2>
                    <p>We offer regular open enrollment training classes at our training facility, which provide a cost-effective way for employees to acquire the skills they need.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ItTrainingServices;
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';
import { db } from '../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";

const Portfolio = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'Portfolio'
    };
    push(ipPageRef, pageData);


    return (
        <div className='my-20 px-10 lg:px-20'>
            <Helmet>
                <title>Portfolio</title>
                <meta
                    name="description"
                    content="BRITS is a leading provider of IT services and solutions. We are a team of experts who are passionate about technology and innovation. We are committed to helping our clients achieve their business goals."
                />
            </Helmet>
            <h1 className='text-5xl text-center font-semibold'>PORTFOLIO</h1>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 my-10'>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://diginationmea.com/w/wp-content/uploads/2021/06/powerbi-logo.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link to="/power-bi">
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">LEARN MORE</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/ServiceNow_logo.svg/2560px-ServiceNow_logo.svg.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://download.logo.wine/logo/MuleSoft/MuleSoft-Logo.wine.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://www.seraphscience.com/wp-content/uploads/2018/06/logo-salesforce-png-454.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://zeevector.com/wp-content/uploads/Microsoft-Azure-DevOps-logo.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Terraform_Logo.svg/1280px-Terraform_Logo.svg.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://openspectruminc.com/wp-content/uploads/2017/05/Phoenix-Nap-Logo.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://www.nicepng.com/png/full/858-8585442_cloud-native-intelligence-for-ibm-cloud-ibm-cloud.png" alt="" className="w-[100px] md:w-[180px] mx-auto" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Dell_Logo.png/1200px-Dell_Logo.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Hewlett_Packard_Enterprise_logo.svg/2560px-Hewlett_Packard_Enterprise_logo.svg.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://www.freepnglogos.com/uploads/vmware-png-logo/partners-login-vmware-png-logo-10.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Citrix.svg/1200px-Citrix.svg.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/DigitalOcean_logo.svg/1024px-DigitalOcean_logo.svg.png" alt="" className="w-[100px] md:w-[180px] mx-auto" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://cdn.veeam.com/content/dam/veeam/global/veeam-graphics/veeam_logo_topaz-500.png.web.1920.1920.webp?ck=1647692565527" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Nutanix_Logo.svg/2560px-Nutanix_Logo.svg.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Ansible_logo.svg/1664px-Ansible_logo.svg.png" alt="" className="w-[100px] md:w-[130px] mx-auto" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/f/f8/Splunk_logo.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e3/Jenkins_logo_with_title.svg/2560px-Jenkins_logo_with_title.svg.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://venturefizz.com/sites/default/files/m/bizzpages/logo/athenahealth_logo.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://www.intelerad.com/en/wp-content/uploads/sites/2/2022/08/Epic-logo-transparent-e1659637275781.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://iconape.com/wp-content/files/aa/347920/svg/advancedmd-seeklogo.com.svg" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://download.logo.wine/logo/Pegasystems/Pegasystems-Logo.wine.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/7/71/HubSpot_Logo.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://www.newcannabisventures.com/wp-content/uploads/2021/10/dutchie-new-logo.jpg" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://download.logo.wine/logo/Microsoft_Azure/Microsoft_Azure-Logo.wine.png" alt="" className="object-center w-full rounded-t-md h-25" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/1024px-Amazon_Web_Services_Logo.svg.png" alt="" className="w-[100px] md:w-[200px] mx-auto" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full rounded-md shadow-md h-[200px] md:h-[250px] lg:h-[250px] xl:h-[300px]">
                    <div className='h-[80%] flex items-center p-2 lg:p-5 xl:p-10'>
                        <img src="https://png.monster/wp-content/uploads/2020/11/cloud_lockup-669717fc.png" alt="" className="w-[100px] md:w-[180px] mx-auto" />
                    </div>
                    <div className="flex flex-col justify-between space-y-8 h-[20%] px-2">
                        <Link>
                            <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md btn-success btn-sm text-white">Coming Soon</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
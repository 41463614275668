import React, { useEffect } from 'react';
import FileUploadForm from '../../Components/FileUploadForm/FileUploadForm';
import ReactGA from 'react-ga';
import { db } from '../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";


const IosDeveloper = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.event({
            category: "Job Openings",
            action: "IOS Developer",
            label: "IOS Developer",
        });
    }, []);

    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'IOS Developer'
    };
    push(ipPageRef, pageData);


    return (
        <div className='px-10'>
            <div className='py-20 bg-sky-500 mb-10'>
                <h1 className='text-center text-6xl text-white'>IOS Developer</h1>
            </div>
            <h1 className='text-2xl'><span className='font-bold'>Location:</span>     Phoenix ,Az</h1>
            <h1 className='font-bold text-2xl my-3'>Job Description:</h1>
            <p className=' text-gray-600'>
                Experience developing for macOS and iOS platforms5 or more years of professional programming experience using Swift and Objective-C programming language. <br /> Demonstrable experience getting applications developed within the given time. <br /> Hand-on experience with AVFoundation and Video Frameworks will be a plus. <br /> Develop, improve and maintain iOS applicationsKnowledge of Git will be a add-on. <br /> Use agile development and testing methodologies to ensure ongoing high quality code timely feature turnaround. <br /> Understanding of the system architecture. Given a requirement ability to come up with the architecture, ability to suggest solutions and identify loopholes in system. <br /> Need to have holistic view of system -and develop effective solutions. <br /> Collaborate with cross-functional teams to define, design, and ship new features.Unit-test code for robustness, including edge cases, usability, and general reliability. <br /> Work on bug fixing and improving application performance. <br /> Continuously discover, evaluate, and implement new technologies to maximize development efficiency
            </p>
            <h1 className='font-bold text-2xl my-3'>Qualifications</h1>
            <p className=' text-gray-600'>B.A or B.S or B.tech / Masters  Required</p>
            <h1 className='font-bold text-2xl my-3'>Required Skills/Experience</h1>
            <p className=' text-gray-500'>
                The Swift 3.0 programming language <br />
                Apple’s Xcode IDE <br />
                Spatial Reasoning <br />
                Design Guidelines <br />
                UI and UX design experience <br />
                Apple Human Interface Guidelines. <br />
                Networking <br />
                Core Data
            </p>
            <h1 className='font-bold text-2xl my-3'>Recommended Skills/Experience</h1>
            <p className=' text-gray-500'>
                MySQL & Oracle (in terms of databases) <br />
                Java and JavaScript(languages plus the frameworks) <br />
                Github.
            </p>
            <FileUploadForm />
        </div>
    );
};

export default IosDeveloper;
import React from 'react';
import { Link } from 'react-router-dom';
const Main = () => {
    return (
        <div className='mt-10'>
            <h1 className='text-3xl text-center text-red-500'>CAREERS AT BLACKROCK IT SOLUTIONS</h1>
            <p className='text-center text-5xl'>Work With The Best In The Industry</p>
            <div className='mt-20 bg-base-200 rounded-lg py-10'>
                <p className='text-center text-3xl'>Current Positions Available</p>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 px-5 my-10'>
                    <div className='card shadow-xl p-5 bg-white'>
                        <div className='h-[150px]'>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Android_Developers_Logo.svg/1280px-Android_Developers_Logo.svg.png" alt="" />
                        </div>
                        <Link to="/careers/android-developer" className='btn btn-success'>Android Developer</Link>
                    </div>
                    <div className='card shadow-xl p-5 bg-white'>
                        <div className='h-[150px]'>
                            <img src="https://logos-world.net/wp-content/uploads/2022/07/Java-Symbol.png" alt="" className='h-[100px] mx-auto' />
                        </div>
                        <Link to="/careers/java-architect" className='btn btn-success'>Java Architect</Link>
                    </div>
                    <div className='card shadow-xl p-5 bg-white'>
                        <div className='h-[150px]'>
                            <img src="https://www.pegasusone.com/wp-content/uploads/2015/08/fullstacklogo1-1.png" alt="" />
                        </div>
                        <Link to="/careers/full-stack-developer" className='btn btn-success'>Full Stack Developer</Link>
                    </div>
                    <div className='card shadow-xl p-5 bg-white'>
                        <div className='h-[150px]'>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Apple_Developer_brandmark.svg/1280px-Apple_Developer_brandmark.svg.png" alt="" />
                        </div>
                        <Link to="/careers/ios-developer" className='btn btn-success'>IOS Developer</Link>
                    </div>
                    <div className='card shadow-xl p-5 bg-white'>
                        <div className='h-[150px]'>
                            <img src="https://angular.io/assets/images/logos/angularjs/AngularJS-Shield.svg" alt="" className='h-[100px] mx-auto' />
                        </div>
                        <Link to="/careers/angular-developer" className='btn btn-success'>Angular Js Developer</Link>
                    </div>
                    <div className='card shadow-xl p-5 bg-white'>
                        <div className='h-[150px]'>
                            <img src="https://logos-download.com/wp-content/uploads/2016/09/React_logo_wordmark.png" alt="" className='h-[100px] mx-auto' />
                        </div>
                        <Link to="/careers/reactjs-developer" className='btn btn-success'>React Js Developer</Link>
                    </div>
                    <div className='card shadow-xl p-5 bg-white'>
                        <div className='h-[150px]'>
                            <img src="https://support.pingidentity.com/servlet/servlet.FileDownload?file=00P1W00001bmvt2UAA" alt="" className='h-[100px] mx-auto' />
                        </div>
                        <Link to="/careers/data-power-developer" className='btn btn-success'>Data Power Developer</Link>
                    </div>
                    <div className='card shadow-xl p-5 bg-white'>
                        <img className='w-1/2 h-[147px] p-5 mx-auto' src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/v1480853298/ghq7zjyjd32vtyplw6cw.png" alt="" />
                        <Link to="/careers/backbase-developer" className='btn btn-success'>Back Base Developer</Link>
                    </div>
                    <div className='card shadow-xl p-5 bg-white'>
                        <img className=' h-[147px] p-5 mx-auto' src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Devops-toolchain.svg/1280px-Devops-toolchain.svg.png" alt="" />
                        <Link className='btn btn-success'>DevOps Developer</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;
import { RouterProvider } from 'react-router-dom';
import './App.css';
import router from './Routes/Routes/Routes';
import ReactGA from 'react-ga';
import { useEffect } from 'react';


function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const trackingId = "G-RZNB3BG1X6";
    ReactGA.initialize(trackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;

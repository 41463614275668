import React from 'react';

const DristributionPartners = () => {
    return (
        <div className='my-20'>
            <h1 className='text-center text-5xl'>DISTRIBUTION PARTNERS</h1>
            <div>
                <div className='grid grid-cols-2  md:grid-cols-3 lg:grid-cols-6 w-4/5 mx-auto borderCss my-5 p-5 items-center gap-2 justify-items-center'>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Avnet_logo.svg/2560px-Avnet_logo.svg.png" alt="" className='w-[145px]' />
                    <img src="https://www.dutchdatacenters.nl/wp-content/uploads/2021/07/blackbox-logo-website.png" alt="" className='w-[145px]' />
                    <img src="https://www.computerplug.com/images/new_logos/logo_500.jpg" alt="" className='w-[145px]' />
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/CDW_Logo.svg/2560px-CDW_Logo.svg.png" alt="" className='w-[145px] h-[50px]' />
                    <img src="https://www.dandhshop.com/assets/images/SUPPORT/LOGO.png" alt="" className='w-[145px] h-[50px]' />
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPwFzxJzYsod2Qb-KOoxsfzew3Gk1rbrD8gxzB0TN6&s" alt="" className='w-[145px]' />
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsxGIYeApFxN07Z9K14BsGB8WOYK-jPV_ej1smyEGRRrJ20KDlTlTWgxqQz8fBj827uA&usqp=CAU" alt="" className='w-[145px]' />
                    <img src="https://elcosystems.com/wp-content/uploads/2019/05/Elco-Logo-2018.jpg" alt="" className='w-[145px]' />
                    <img src="https://www.eprom.com.eg/images/EPROMLogo20th.gif" alt="" className='w-[145px]' />
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Ingram_Micro_logo_new.svg/2560px-Ingram_Micro_logo_new.svg.png" alt="" className='w-[145px]' />
                    <img src="https://www.channele2e.com/wp-content/uploads/2020/05/Logo-Sherweb.png" alt="" className='w-[145px]' />
                    <img src="https://upload.wikimedia.org/wikipedia/commons/8/85/TD_SYNNEX_logo_file.png" alt="" className='w-[145px]' />
                </div>
            </div>
        </div>
    );
};

export default DristributionPartners;
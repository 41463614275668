import React, { useEffect } from 'react';
import Lottie from "lottie-react";
import powerBiAnimation from "../../Assets/Animations/power-bi.json";
import img1 from "../../Assets/Power-bi/power-bi-1.png";
import img2 from "../../Assets/Power-bi/power-bi-2.png";
import img3 from "../../Assets/Power-bi/power-bi-3.png";
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';
import { db } from '../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";


const PowerBi = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        const trackingId = "G-RZNB3BG1X6";
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'Power Bi'
    };
    push(ipPageRef, pageData);


    return (
        <div>
            <Helmet>
                <title>Power BI</title>
                <meta
                    name="description"
                    content="BRITS is a leading provider of IT services and solutions. We are a team of experts who are passionate about technology and innovation. We are committed to helping our clients achieve their business goals."
                />
            </Helmet>
            <section className="">
                <div className="grid grid-cols-1 lg:px-8 md:grid-cols-2 items-center py-20 justify-center">
                    <div className='flex justify-center'>
                        <img src="https://diginationmea.com/w/wp-content/uploads/2021/06/powerbi-logo.png" alt="" />
                    </div>
                    <div>
                        <Lottie animationData={powerBiAnimation} className="w-4/5" />
                    </div>
                </div>
                <div className='py-20'>
                    <h1 className='text-5xl text-center'>OVERVIEW</h1>
                    <div className='grid grid-cols-1 md:grid-cols-2 justify-items-center my-20'>
                        <div className="card card-compact w-[350px] lg:w-[500px] bg-base-100 shadow-xl mt-5">
                            <figure><img src={img2} alt="Album" /></figure>
                            <div className="card-body">
                                <h2 className="card-title">Pharma Sales Dashboard</h2>
                                <p>Click the button to view Power-BI</p>
                                <div className="card-actions justify-end">
                                    <label htmlFor="pharma-bi" className="btn btn-primary">View</label>
                                </div>
                            </div>
                        </div>
                        <div className="card card-compact w-[350px] lg:w-[500px] bg-base-100 shadow-xl mt-5">
                            <figure><img src={img3} alt="Album" /></figure>
                            <div className="card-body">
                                <h2 className="card-title">Telangana State Panchayat Department Dashboard</h2>
                                <p>Click the button to view Power-BI</p>
                                <div className="card-actions justify-end">
                                    <label htmlFor="telangana-bi" className="btn btn-primary">View</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-around'>
                        <div className="card card-compact w-[350px] lg:w-[500px] bg-base-100 shadow-xl">
                            <figure><img src={img1} alt="Album" /></figure>
                            <div className="card-body">
                                <h2 className="card-title">HR Dashboard - Employee Details</h2>
                                <p>Click the button to view Power-BI</p>
                                <div className="card-actions justify-end">
                                    <label htmlFor="hrd-bi" className="btn btn-primary">View</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <input type="checkbox" id="pharma-bi" className="modal-toggle" />
            <div className="modal">
                <div className=" max-w-[1050px] max-h-[650px] modal-box relative">
                    <label htmlFor="pharma-bi" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <iframe title="Pharma Sales Dashboard" width="1000" height="600" src="https://app.powerbi.com/view?r=eyJrIjoiYjVlOWYzNjMtMTcyZS00MWYzLTgzZTQtNDdlZjBkZDUyYzZmIiwidCI6IjYyZDJjMWE1LWQ1MzEtNGY2Mi05NjVlLTQ4NzQzNGUxZWVkNiIsImMiOjN9&pageName=ReportSection" frameborder="0" allowFullScreen="true"></iframe>
                </div>
            </div>
            <input type="checkbox" id="telangana-bi" className="modal-toggle" />
            <div className="modal">
                <div className=" max-w-[1050px] max-h-[650px] modal-box relative">
                    <label htmlFor="telangana-bi" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <iframe title="Telangana State Panchayat Department Dashboard" width="1000" height="600" src="https://app.powerbi.com/view?r=eyJrIjoiYTNiOGZkMDktNmEwNC00YjcyLTk0NWQtOTlhMDg3MjRhYjU5IiwidCI6IjYyZDJjMWE1LWQ1MzEtNGY2Mi05NjVlLTQ4NzQzNGUxZWVkNiIsImMiOjN9&embedImagePlaceholder=true&pageName=ReportSection9a790b3528ddf86156a3" frameborder="0" allowFullScreen="true"></iframe>
                </div>
            </div>
            <input type="checkbox" id="hrd-bi" className="modal-toggle" />
            <div className="modal">
                <div className=" max-w-[1050px] max-h-[650px] modal-box relative">
                    <label htmlFor="hrd-bi" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <iframe title="HR Dashboard - Employee Details" width="1000" height="600" src="https://app.powerbi.com/view?r=eyJrIjoiMWM4NWE0NWItZWVhNi00YzQ1LTlmOWEtZWZiYzdlMDJjNzEyIiwidCI6IjYyZDJjMWE1LWQ1MzEtNGY2Mi05NjVlLTQ4NzQzNGUxZWVkNiIsImMiOjN9" frameborder="0" allowFullScreen="true"></iframe>
                </div>
            </div>
        </div>
    );
};

export default PowerBi;
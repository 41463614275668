import React, { useEffect } from 'react';
import FileUploadForm from '../../Components/FileUploadForm/FileUploadForm';
import ReactGA from 'react-ga';
import { db } from '../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";


const BackbaseDeveloper = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.event({
            category: 'Job Openings',
            action: 'Backbase Developer',
            label: 'Backbase Developer',
        });
    }, []);

    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'Backbase Developer'
    };
    push(ipPageRef, pageData);


    return (
        <div className='px-10'>
            <div className='py-20 bg-sky-500 mb-10'>
                <h1 className='text-center text-6xl text-white'>Backbase Developer</h1>
            </div>
            <h1 className='text-2xl'><span className='font-bold'>Location:</span>     Phoenix ,Az</h1>
            <h1 className='font-bold text-2xl my-3'>About this position :</h1>
            <p className=' text-gray-600'>
                Your job will be to analyze and model business processes and online web forms to create user-friendly, online self-
                service applications. <br /> You will help our clients implement an outside-in (customer-centric) design philosophy and drive
                business value with our software. <br /> No matter the client, you will be able to align different project stakeholders to
                work towards one single goal. <br />
                You implement complex forms with the goal of creating a dynamic solution using the full capability of the model tool. <br />
                You participate in inceptions, deliver solutions to customers based on their use cases and run full forms
                implementations.
            </p>
            <h1 className='font-bold text-2xl my-3'>Duties and Responsibilities :</h1>
            <p className=' text-gray-600'>
                Direct Integration Services – Backbase 6, Backbase Infrastructure & Platform Services, API-GW Integration of user facing elements developed by a front end developers with server side logic. <br />
                Building reusable code and libraries for future use. <br />
                Optimization of the application for maximum speed and scalability. <br />
                Implementation of security and data protection. <br />
                Backbase Forms Developer / Modeler (version #11.x) <br />
                Expertise on Forms Studio, Forms Runtime & Forms Publisher (version #11.x) <br />
                Experience in technical business modeller. <br />
                Good understanding of Backbase Forms and well experienced in similar model driven development environments as
                modeler developer. <br />
                Good understanding of the commercial banking business. <br />
                Good experience in Agile execution and especially in Scrum. <br />
                Experience in Devops adoption and implementation. <br />
                Good verbal and written communication skills. <br />
                Having Backbase Certification will be an added advantage.
            </p>
            <h1 className='font-bold text-2xl my-3'>Qualifications</h1>
            <p className=' text-gray-600'>
                Bachelor’s degree in Computer Science or closely related field of study <br />
                Should have 6-8 years of hands-on experience in application development in an enterprise setup. <br />
                Backend Developer responsible for managing the interchange of data between the server and the client. <br />
                Primary focus will be development of all server side logic definition and maintenance of the central database and
                ensuring high performance and responsiveness to requests from the front end.
            </p>
            <h1 className='font-bold text-2xl my-3'>Required Skills/Experience</h1>
            <p className=' text-gray-500'>
                Should have 6-8 years of hands-on experience in application development in an enterprise setup. <br />
                Strong OOPS knowledge, including experience with design patterns. <br />
                Experience with Java/JEE and open source frameworks/tools like Spring, Hibernate, Apache Camel, Maven, Ant,
                Subversion, Git, xUnit etc. <br />
                Expertise on Backbase 6, IPS, Java/J2EE, Microservices Architecture, API-GW, Spring Boot, Spring Cloud, Spring
                Integration, Spring Security, WebServices (RESTful + SOAP), Maven, CI and CD, JUnit, component integration, test
                automation. <br />
                Solid background in software development including design. <br />
                Experience with microservices architecture (Netflix OSS is a plus). <br />
                Experience with non-functional such as security, performance, scalability etc. <br />
                Being a Full Stack developer is a huge advantage. <br />
                Solid understanding of Enterprise Integration Patterns. <br />
                Experience with relational databases like MySQL/Oracle. <br />
                Experience with Cloud and traditional application servers like Tomcat/Weblogic/Websphere. <br />
                Expertise on Build Tools Maven, Github and Agile Methodologies.
            </p>
            <h1 className='font-bold text-2xl my-3'>Recommended Skills/Experience</h1>
            <p className=' text-gray-500'>
                Must have front end skills like HTML5, CCS, JS, Type Script, Bootstrap4, Angualr2 and above. <br />
                Proficient understanding of web markup including HTML5 CSS3. <br />
                Basic understanding of server side CSS pre processing platforms such as LESS and SASS <br />
                Proficient understanding of client side scripting and JavaScript frameworks including jQuery. <br />
                Good understanding of asynchronous request handling partial page updates and AJAX. <br />
                Proficient understanding of cross browser compatibility issues and ways to work around them. <br />
                Proficient understanding of code versioning tools such as Git BitBucket.
                Excellent analytical aptitude and problem solving skills. <br />
                Excellent communication and customer interfacing skills. <br />
                Able to communicate clearly and confidently across all levels to get buy-ins from technical stakeholders as well as from
                your team members. <br />
                Experience in the banking domain or Fintech products will be considered an advantage <br />
                Excellent communication skills in English, spoken and written.
            </p>
            <FileUploadForm />
        </div>
    );
};

export default BackbaseDeveloper;
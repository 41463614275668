import React from 'react';
import Banner from '../Banner/Banner';
import ItConsultingServices from '../ItConsultingServices/ItConsultingServices';
import { Helmet } from 'react-helmet-async';
import { db } from '../../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";

const ItConsulting = () => {
    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'IT Consulting'
    };
    push(ipPageRef, pageData);


    return (
        <div>
            <Helmet>
                <title>IT Consulting</title>
                <meta
                    name="description"
                    content="BRITS is a leading provider of IT services and solutions. We are a team of experts who are passionate about technology and innovation. We are committed to helping our clients achieve their business goals."
                />
            </Helmet>
            <Banner></Banner>
            <ItConsultingServices></ItConsultingServices>
        </div>
    );
};

export default ItConsulting;
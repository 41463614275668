import React from 'react';
import Banner from '../Banner/Banner';
import ItTrainingServices from '../ItTrainingServices/ItTrainingServices';
import { Helmet } from 'react-helmet-async';
import { db } from '../../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";

const ItTraining = () => {
    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'IT Training'
    };
    push(ipPageRef, pageData);



    return (
        <div>
            <Helmet>
                <title>IT Training</title>
                <meta
                    name="description"
                    content="BRITS is a leading provider of IT services and solutions. We are a team of experts who are passionate about technology and innovation. We are committed to helping our clients achieve their business goals."
                />
            </Helmet>
            <Banner></Banner>
            <ItTrainingServices></ItTrainingServices>
        </div>
    );
};

export default ItTraining;
import React from 'react';
import Founder from '../Founder/Founder';
import MeetTheTeam from '../MeetTheTeam/MeetTheTeam';
import OverView from '../OverView/OverView';
import { Helmet } from 'react-helmet-async';
import { db } from '../../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";
import img from "./../../../Assets/Images/ourstory-main.png"

const OurStory = () => {
    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'Our Story'
    };
    push(ipPageRef, pageData);
    return (
        <div>
            <Helmet>
                <title>BRITS - Our Story</title>
                <meta
                    name="description"
                    content="BRITS is a leading provider of IT services and solutions. We are a team of experts who are passionate about technology and innovation. We are committed to helping our clients achieve their business goals."
                />
            </Helmet>
            {/* <OverView />
            <Founder />
            <MeetTheTeam /> */}
            <div className='lg:max-w-[1440px] w-full mx-auto lg:flex lg:p-[100px] md:p-[50px] p-[20px] lg:h-[70vh]'>
                <div className='flex lg:w-1/2 items-start'>
                    <div className='md:text-[64px] text-[42px] font-bold'>
                        We build bridges <br /> between <span className='text-[#7C7C7C]'>companies <br />
                        and customers</span>
                    </div>
                </div>
                <div className='flex lg:w-1/2 items-end'>
                    <div className='text-[18px]'>
                        Before BlackRock IT Solutions was born, we found that traditional job boards search methods where outdated and led to dead ends. We found ourselves frustrated and knew hiring managers couldn't connect with the right talent. The same candidates where getting recycled over and over. It just wasn't cutting it.
                    </div>
                </div>
            </div>
            <div className='lg:max-w-[1440px] w-full mx-auto flex justify-center mb-[100px]'>
                <img src={img} alt="" />
            </div>
            <div className='max-w-[1440px] mx-auto lg:flex lg:p-[100px] md:p-[50px] p-[20px] lg:h-[80vh] border-t-2 border-b-2 border-[#7C7C7C]'>
                <div className='flex lg:w-1/2 items-start'>
                    <div className='md:text-[64px] text-[42px] font-bold'>
                        One Stop Solution <br /> For All Your <span className='text-[#7C7C7C]'>IT <br /> Needs</span>
                    </div>
                </div>
                <div className='flex lg:w-1/2 items-end'>
                    <div>
                        <div className='text-[18px] mb-[20px]'>
                            We formed BlackRock IT Solutions in order to change the game. So, what makes us different?
                        </div>
                        <div className='text-[18px]'>
                            Our uniquely built and exclusive software system and personal approach create an experience you will not soon forget. Click the link below to find out more about our proprietary system for delivering the right candidate directly to your inbox. After successfully finding 3% hidden talent we wanted to solve the Issues they are facing in day-to-day operations and from those conversations we have expanded our portfolio from recruiting to Managed Services, Software Consulting, Software Development and Software Training.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurStory;
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { ref } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBa0oq-U_KnhDHsT6dDSsk705aDXyRCjuI",
    authDomain: "blackrock-it-solutions.firebaseapp.com",
    databaseURL: "https://blackrock-it-solutions-default-rtdb.firebaseio.com/",
    projectId: "blackrock-it-solutions",
    storageBucket: "blackrock-it-solutions.appspot.com",
    messagingSenderId: "931625124932",
    appId: "1:931625124932:web:3af38b573d30856297e811"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const databaseRef = ref(db, 'job-page-resume');
export const storage = getStorage(app);
export default app;

import React from 'react';
import SoftDev from "../../../Assets/Images/software-development.gif";

const Banner = () => {
    return (
        <div className="grid grid-cols-1 lg:px-8 md:grid-cols-2 items-center py-20 justify-center">
            <div className='flex justify-center'>
                <h1 className='text-center text-5xl font-semibold'>We’re building <span className='text-red-500'>Software</span> <br />
                    for you</h1>
            </div>
            <div>
                <img src={SoftDev} alt="" className='mx-auto' />
            </div>
        </div>
    );
};

export default Banner;
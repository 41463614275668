import React, { useContext } from "react";
import { Chart } from "react-google-charts";
import { AuthContext } from "../../../../Context/AdminContext";
import MostPageView from "../MostPageView/MostPageView";

const TopVisitMap = () => {
  const { rootData } = useContext(AuthContext);

  const newData = [];
  for (const key in rootData) {
    const element = rootData[key];
    newData.push(element.country);
  }

  const countryName = [...new Set(newData)];
  const countryCount = countryName.map(
    (name) => newData.filter((item) => item === name).length
  );
  const countryData = countryName.map((name, index) => [
    name,
    countryCount[index],
  ]);
  countryData.unshift(["Country", "Popularity"]);
  countryData.sort((a, b) => b[1] - a[1]);
  const colors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
  ];

  return (
    <div>
      <div className="border border-[#bfbbbb] my-2 rounded-xl">
        <h1 className="text-2xl font-bold p-2">Visitor's Locations</h1>
        <hr />
        <div className="xl:h-[580px] lg:h-[500px] md:h-[300px] h-[280px] w-[100%] bg-white rounded-xl p-2">
          <Chart
            width={"100%"}
            height={"100%"}
            chartType="GeoChart"
            data={countryData}
            options={{
              colorAxis: { colors: colors.slice(0, countryData.length) },
              region: "world",
              resolution: "countries",
              legend: "none",
              tooltip: { trigger: "focus" },
              keepAspectRatio: false,
              responsive: true,
              plugins: {
                legend: {
                  position: "bottom",
                },
                title: {
                  display: true,
                  text: "Top Visited Countries",
                },
              },
            }}
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 my-2 gap-4">
        <div className="p-3 border border-[#bfbbbb] rounded-xl">
          <h3 className="text-center text-2xl font-semibold  py-2 mb-5">
            Top Visited Countries
          </h3>
          <hr />
          <ul className="flex flex-col gap-2 rounded-xl p-2">
            {countryData.map((item, index) => {
              if (index !== 0) {
                return (
                  <li key={index} className="flex justify-between">
                    <span>{item[0]}</span>
                    <span>{item[1]}</span>
                  </li>
                );
              }
            })}
          </ul>
        </div>
        <MostPageView />
      </div>
    </div>
  );
};

export default TopVisitMap;

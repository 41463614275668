import React, { useEffect } from 'react';
import FileUploadForm from '../../Components/FileUploadForm/FileUploadForm';
import ReactGA from 'react-ga';
import { db } from '../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";


const JavaArchitect = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.event({
            category: "Job Openings",
            action: "Java Architect",
            label: "Java Architect",
        });
    }, []);

    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'Java Architect'
    };
    push(ipPageRef, pageData);


    return (
        <div className='px-10'>
            <div className='py-20 bg-sky-500 mb-10'>
                <h1 className='text-center text-6xl text-white'>Java Architect</h1>
            </div>
            <h1 className='text-2xl'><span className='font-bold'>Location:</span>     Phoenix,Az</h1>
            <h1 className='text-2xl'><span className='font-bold'>Experience:</span>     13+ Years</h1>
            <h1 className='font-bold text-2xl my-3'>Job Description:</h1>
            <p className=' text-gray-600'>Experience into Nodejs, typescript/Javascript, ES6, AWS.Strong proficiency in JavaScript, HTML, CSS, Ajax. <br /> Need to have good experience in Microservices.Typescript, css, html exp in solutions, architecture. Good exp in code review, code quality tools excellent communication skills work exp in agile, scrum. <br /> Should have at least 3 + years of experience in Node.JS development. <br /> Should have solid experience in API Background and Mongo DBShould have experience in Backend language . NET or Python or Java. <br /> Should have exposure to CI/CD Process7+ years of professional JavaScript development. <br /> Understanding the nature of asynchronous programming and its quirks and workarounds. <br /> Comfortable working through the entire stack from user interface through systems levels. <br /> Understanding accessibility and security compliance. <br /> Experience in consuming web services (REST) and processing data (JSON)Experience with SQLite, NoSQL, Redis or other databases. <br /> Proven experience in building API services using NodeJS Express and related frameworks. <br /> Good understanding on NodeJS asynchronous runtime. <br /> Good understanding on JS concepts on callbacks and closures. <br /> Experience in debugging issues related to memory leaks
                Experience in building highly scalable and high throughput services with sub second response times.</p>
            <h1 className='font-bold text-2xl my-3'>Qualifications</h1>
            <p className=' text-gray-600'>B.A or B.S or B.tech / Masters  Required</p>
            <h1 className='font-bold text-2xl my-3'>Required Skills</h1>
            <p className=' text-gray-500'>
                Java <br />
                Web Services <br />
                API <br />
                Maven <br />
                Microservices <br />
                Jenkins <br />
                Git <br />
                Scrum <br />
                Json <br />
                Design Patterns <br />
                Html <br />
                XML <br />
                JMS <br />
                CSS <br />
                Architecture
            </p>
            <h1 className='font-bold text-2xl my-3'>Recommended Skills</h1>
            <p className=' text-gray-500'>
                Knowledge of software architecture. <br />
                Web programming abilities. <br />
                Software design. <br />
                Design and implementation of networks. <br />
                Java, Java Servlets, Java Beans and JavaScript. <br />
                Project management. <br />
                Ability to present technical information.
            </p>
            <FileUploadForm />
        </div>
    );
};

export default JavaArchitect;
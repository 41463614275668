import React from 'react';
import Lottie from "lottie-react";
import helpdeskAnim from "../../../Assets/Animations/helpdesk.json"
import trackingAnim from "../../../Assets/Animations/tracking.json";
import planningAnim from "../../../Assets/Animations/planning.json";
import chartAnim from "../../../Assets/Animations/chartNew.json";
import securityAnim from "../../../Assets/Animations/security.json";

const ManagedServices = () => {
    return (
        <div className='px-10'>
            <h1 className='text-5xl text-center'>Managed Services</h1>
            <p className='my-5'>Take advantage of our expertise in order to save time and stay up-to-date. SmartLayer’s managed services will handle all your company’s IT needs, both day-to-day and long-term. We follow the protocols laid out in the Information Technology Infrastructure Library (ITIL), which provides generalized procedures, compliance checks, and measures of improvement, meaning that we adhere to best practices for matching our services to your business needs.</p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2">
                <figure className='p-5 md:w-1/2 lg:w-[350px]'>
                    <Lottie className='w-full' animationData={helpdeskAnim}></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Service Help Desk</h2>
                    <p>Get effective help with any IT issue, right when you need it. Our help desk operates 24/7, and you will be dealing with people who know your systems and your company. This allows for rapid solutions, meaning that you can get back to work immediately.</p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[350px]'>
                    <Lottie className='w-full' animationData={trackingAnim}></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Asset Management and Tracking</h2>
                    <p>SmartLayer will monitor and manage all of your computers and technology. We track warranties, protect against viruses, and keep systems up-to-date. We take a proactive role in keeping everything working at peak efficiency to avoid major issues in the future.</p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[350px]'>
                    <Lottie className='w-full' animationData={planningAnim}></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Technology Strategy Planning</h2>
                    <p>Our representatives can help you develop a long-term plan which exactly suits your needs. By utilizing our experts, you ensure that you always have the technology you need, and that you don’t fall behind in this rapidly changing field.</p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[350px]'>
                    <Lottie className='w-full' animationData={chartAnim}></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Quarterly Review</h2>
                    <p>SmartLayer will make sure that all of your IT information is available and clearly presented in our quarterly reviews. Avoid wasting time weeding through data, an focus instead on the important results, and what they mean for your company.</p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[350px]'>
                    <Lottie className='w-full' animationData={securityAnim}></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Managed Security Services</h2>
                    <p>We will implement and monitor your security systems, using a turnkey network, perimeter, or other system security solutions. We will setup and manage your firewall, multi-factor authentication protocols, and respond to threats in real time to ensure that your data is always secure.</p>
                </div>
            </div>
        </div>
    );
};

export default ManagedServices;
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import ReCAPTCHA from "react-google-recaptcha";
import recaptcha from "../../Components/b14ck/B14ck.js";
import jobsImg from "../../Assets/Images/jobs-banner-img.jpg";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { push } from "firebase/database";
import { storage, databaseRef } from '../../Firebase/firebase.config.js';

const Jobs = () => {
    const [verified, setVerified] = useState(false);
    function onChange(value) {
        if (value) {
            setVerified(true)
        }
        setTimeout(() => {
            setVerified(false)
        }, 15000)
    }

    // sendEmail post request to send email to the user with data provided
    const sendEmail = async (data) => {
        const response = await fetch('http://localhost:5000/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const result = await response.json();
        return result;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;
        const firstName = form.user_first_name.value;
        const lastName = form.user_last_name.value;
        const email = form.email_address.value;
        const phone = form.phone_number.value;
        const linkedin = form.linkedin_url.value;
        const role = form.role.value;
        const resume = form.resume.files[0];
        const storageRef = ref(storage, `job-page-resume/${resume.name}`);
        const uploadTask = await uploadBytes(storageRef, resume);
        const resumeUrl = await getDownloadURL(uploadTask.ref);
        const job = form.job.value;
        const visaStatus = form.visa_status.value;
        const data = {
            firstName,
            lastName,
            email,
            phone,
            linkedin,
            role,
            resumeUrl,
            job,
            visaStatus
        }
        if (verified) {
            push(databaseRef, data);
            sendEmail(data);
            toast.success('Your application has been submitted successfully');
            form.reset();
        }
        else {
            toast.error('Please verify that you are not a robot');
        }
    };

    return (
        <div className='pt-10'>
            <h1 className='text-center text-6xl'>BRITS Job Community</h1>
            <div className="hero">
                <div className="flex items-center justify-center gap-4 p-4 w-[90%] flex-col lg:flex-row">
                    <img src={jobsImg} className="max-w-2xl rounded-lg shadow-2xl" alt='' />
                    <div className='text-lg text-justify'>
                        <p className='my-3'>At BRITS, we understand the challenges many people are facing due to job loss caused by the current economic downturn and other factors. As a company committed to making a positive impact in the world, we want to help those in need. Our mission is to provide support and resources to individuals who have been impacted by job loss, and to connect them with opportunities that will help them get back on their feet.</p>
                        <p className='my-3'>We believe that everyone deserves access to meaningful work, and we are dedicated to creating opportunities for those in need. Whether you are looking for a contract or full-time position, we are here to help. Our team of experts is committed to providing job seekers with the tools and resources they need to succeed.</p>
                        <p className='my-3'>By joining our community, you'll have access to a wealth of job listings, networking opportunities, and support. We believe in the power of community, and we are committed to creating a supportive environment where job seekers can connect with others and find the resources they need to succeed.</p>
                        <p className='my-3'>At BRITS, we are committed to making a positive impact in the world, and we believe that helping those in need is an important part of that mission. Whether you are a recent college graduate, a seasoned professional, or someone who has been impacted by job loss, we are here to help. Join our community today and take the first step towards your next opportunity.</p>
                    </div>
                </div>
            </div>
            <div className='my-5'>
                <p className='text-center text-lg'>Please Provide Your Information Below to Help Us Better Assist You</p>
            </div>
            <form onSubmit={handleSubmit} className='lg:w-1/2 mx-auto borderCss p-5 my-10'>
                <div className='flex w-full gap-5'>
                    <div className="form-control w-1/2">
                        <label className="label">
                            <span className="label-text">First Name</span>
                        </label>
                        <input name='user_first_name' type="text" placeholder="First Name" className="input input-bordered" required />
                    </div>
                    <div className="form-control w-1/2">
                        <label className="label">
                            <span className="label-text">Last Name</span>
                        </label>
                        <input name='user_last_name' type="text" placeholder="Last Name" className="input input-bordered" required />
                    </div>
                </div>
                <div className='flex w-full gap-5'>
                    <div className="form-control w-1/2">
                        <label className="label">
                            <span className="label-text">Email Address</span>
                        </label>
                        <input name='email_address' type="email" placeholder="Email Address" className="input input-bordered" required />
                    </div>
                    <div className="form-control w-1/2">
                        <label className="label">
                            <span className="label-text">Phone Number</span>
                        </label>
                        <input name='phone_number' type="number" placeholder="Phone Number" className="input input-bordered" required />
                    </div>
                </div>
                <div className='flex w-full gap-5'>
                    <div className="form-control w-1/2">
                        <label className="label">
                            <span className="label-text">Linkedin Profile URL</span>
                        </label>
                        <input name='linkedin_url' type="text" placeholder="Linkedin Profile Url" className="input input-bordered" required />
                    </div>
                    <div className="form-control w-1/2">
                        <label className="label">
                            <span className="label-text">Role</span>
                        </label>
                        <input name='role' type="text" placeholder="Role" className="input input-bordered" required />
                    </div>
                </div>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Attach Your Resume</span>
                    </label>
                    {/* input file only pdf format and not able to change the format to all files */}
                    <input name='resume' type="file" accept="application/pdf" className="input input-bordered" required />
                </div>
                <div className='flex w-full gap-5 mb-5'>
                    <div className="form-control w-1/2">
                        <label className="label">
                            <span className="label-text">Job Type :</span>
                        </label>
                        <select name='job' className="select select-bordered w-full" required>
                            <option value="Full Time">Full Time</option>
                            <option value="C2C">C2C (Company to Company)</option>
                        </select>
                    </div>
                    <div className="form-control w-1/2">
                        <label className="label">
                            <span className="label-text">Visa Status</span>
                        </label>
                        <input name='visa_status' type="text" placeholder="Visa Status" className="input input-bordered" required />
                    </div>
                </div>
                <ReCAPTCHA
                    sitekey={recaptcha}
                    onChange={onChange}
                />
                <button type='submit' className="btn btn-success mt-5 block mx-auto" disabled={!verified}>Submit Application</button>
            </form>
        </div>
    );
};

export default Jobs;
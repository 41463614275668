import React, { useEffect } from 'react';
import FormSection from '../FormSection/FormSection';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';
import { db } from '../../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";


const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'Contact Us'
    };
    push(ipPageRef, pageData);


    return (
        <div>
            <Helmet>
                <title>BRITS - Contact Us</title>
                <meta
                    name="description"
                    content="BRITS is a leading provider of IT services and solutions. We are a team of experts who are passionate about technology and innovation. We are committed to helping our clients achieve their business goals."
                />
            </Helmet>
            <FormSection></FormSection>
        </div>
    );
};

export default ContactUs;
import React, { useEffect, useState } from 'react';
import Banner from '../Banner/Banner';
import History from '../History/History';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Services from '../Services/Services';
import Portfolio from '../Portfolio/Portfolio';
import Careers from '../Careers/Careers';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { db } from '../../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";
AOS.init();

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    // Get the IP address of the user and save it to the Firebase realtime database
    const [countryS, setCountry] = useState(null);
    const [flagS, setFlag] = useState(null);

    // countryCode to country name and flag from rest country api 
    const converter = (countryCode) => {
        fetch(`https://restcountries.com/v3.1/alpha/${countryCode}`)
            .then(res => res.json())
            .then(data => {
                setCountry(data[0].name?.common);
                setFlag(data[0].flags?.png || data[0].flags?.svg);
            })
            .catch(err => {
                console.log(err);
            });
    };

    function getDeviceType() {
        var width = window.innerWidth;
        if (width <= 576) return "Mobile";
        if (width > 576 && width <= 992) return "Tablet";
        if (width > 992) return "Desktop/Laptop";
    }

    // device info


    useEffect(() => {
        axios.get('https://ipinfo.io/')
            .then(res => {
                const resData = res.data;
                const date = new Date().toLocaleDateString();
                // time to UTC time
                const time = new Date().toLocaleTimeString('en-US', { timeZone: 'UTC' });
                const ip = resData.ip;
                const city = resData.city;
                const countryCode = resData.country;
                converter(countryCode);
                const loc = resData.loc;
                const org = resData.org;
                const ipRef = ref(db, 'userData');
                const ipPageRef = ref(db, 'pageViewData');
                const data = {
                    date: date,
                    time: time,
                    ip: ip,
                    device: getDeviceType(),
                    city: city,
                    country: countryS,
                    flag: flagS,
                    loc: loc,
                    org: org
                };

                const pageData = {
                    pageName: 'Home'
                };

                if (countryS) {
                    push(ipRef, data);
                }
                if (countryS && flagS) {
                    push(ipPageRef, pageData);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, [countryS, flagS]);

    return (
        <div>
            <Helmet>
                <title>BRITS - Home</title>
                <meta
                    name="description"
                    content="BRITS is a leading provider of IT services and solutions. We are a team of experts who are passionate about technology and innovation. We are committed to helping our clients achieve their business goals."
                />
            </Helmet>
            <Banner></Banner>
            <History></History>
            <Services></Services>
            <Portfolio></Portfolio>
            <Careers></Careers>
        </div>
    );
};

export default Home;
import React, { useContext } from 'react';
import { toast } from 'react-hot-toast';
import { AuthContext } from '../../../Context/AdminContext';

const AccountSettings = () => {
    const { admin, updateProfileInfo, updatePasswordInfo } = useContext(AuthContext);
    const { email, displayName, photoURL } = admin;

    const handleUpdateProfile = (e) => {
        e.preventDefault();
        const name = e.target[0].value;
        const photo = e.target[1].value;
        const password = e.target[3].value;
        const confirmPassword = e.target[4].value;
        if (password === confirmPassword) {
            updateProfileInfo(name, photo)
                .then(() => {
                    updatePasswordInfo(password)
                        .then(() => {
                            toast.success('Profile Updated Successfully');
                        })
                        .catch((error) => {
                            toast.error(error.message);
                        });
                })
        }
        else {
            toast.error('Password does not match');
        }
    };

    // updated profile info


    return (
        <div>
            <h1 className='text-center font-bold text-4xl text-red-600'>Account Details</h1>
            <form onSubmit={handleUpdateProfile} className='w-[600px] borderCss p-5 mx-auto mt-5'>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Your Name</span>
                    </label>
                    <input type="text" placeholder="Your Name" className="input input-bordered" defaultValue={displayName} required />
                </div>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Your Photo URL</span>
                    </label>
                    <input type="text" placeholder="Your Photo" className="input input-bordered" defaultValue={photoURL} />
                </div>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Your Email</span>
                    </label>
                    <input type="email" placeholder="Your Email" className="input input-bordered" defaultValue={email} readOnly disabled />
                </div>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Update Your Password</span>
                    </label>
                    <input type="password" placeholder="Your Password" className="input input-bordered" required />
                </div>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Confirm Update Password</span>
                    </label>
                    <input type="password" placeholder="Confirm Password" className="input input-bordered" required />
                </div>
                <button type="submit" className="btn btn-success mt-5 block mx-auto">Update Profile</button>
            </form>
        </div>
    );
};

export default AccountSettings;
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from "../../Assets/Images/br-logo-01.png";
import NavbarCSS from "./Navbar.module.css";
import menuIcon from "../../Assets/Images/menu.png"
const Navbar = () => {
    const menu = <>
        <li><Link
            to="/"
            className={({ isActive }) =>
                isActive ? NavbarCSS.activeStyle : undefined
            }
        >Home</Link></li>
        <li>
            <div>
                About
                <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
            </div>
            <ul className="menu dropdown-content p-2 bg-base-100 shadow-2xl z-20">
                <li><NavLink
                    to="/about/our-story"
                    className={({ isActive }) =>
                        isActive ? NavbarCSS.activeStyle : undefined
                    }
                >Our Story</NavLink></li>
                <li><NavLink
                    to="/about/partners"
                    className={({ isActive }) =>
                        isActive ? NavbarCSS.activeStyle : undefined
                    }
                >Partners</NavLink></li>
                <li><NavLink
                    to="/about/achievements"
                    className={({ isActive }) =>
                        isActive ? NavbarCSS.activeStyle : undefined
                    }
                >Achievements</NavLink></li>
            </ul>
        </li>
        <li>
            <div>
                Services
                <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
            </div>
            <ul className="menu dropdown-content p-2 bg-base-100 shadow-2xl z-20">
                <li><NavLink
                    to="/software-development"
                    className={({ isActive }) =>
                        isActive ? NavbarCSS.activeStyle : undefined
                    }
                >Software Development</NavLink></li>
                <li><NavLink
                    to="/it-consulting"
                    className={({ isActive }) =>
                        isActive ? NavbarCSS.activeStyle : undefined
                    }
                >IT Consulting</NavLink></li>
                <li><NavLink
                    to="/it-managed-services"
                    className={({ isActive }) =>
                        isActive ? NavbarCSS.activeStyle : undefined
                    }
                >IT Managed Services</NavLink></li>
                <li><NavLink
                    to="/it-training"
                    className={({ isActive }) =>
                        isActive ? NavbarCSS.activeStyle : undefined
                    }
                >IT Training</NavLink></li>
                <li><NavLink
                    to="/it-staffing"
                    className={({ isActive }) =>
                        isActive ? NavbarCSS.activeStyle : undefined
                    }
                >IT Staffing</NavLink></li>
                <li><NavLink
                    to="/digital-marketing"
                    className={({ isActive }) =>
                        isActive ? NavbarCSS.activeStyle : undefined
                    }
                >Digital Marketing</NavLink></li>
            </ul>
        </li>
        <li><NavLink
            to="/portfolio"
            className={({ isActive }) =>
                isActive ? NavbarCSS.activeStyle : undefined
            }
        >Portfolio</NavLink></li>
        <li><NavLink
            to="/careers"
            className={({ isActive }) =>
                isActive ? NavbarCSS.activeStyle : undefined
            }
        >Careers</NavLink></li>
    </>
    return (
        <div className="navbar justify-between md:px-5 mx-auto py-4 sticky top-0 z-50 shadow-xl bg-white">
            <div className="navbar-start w-[70%] lg:w-[15%]">
                <div className="dropdown">
                    <label tabIndex={0} className="btn lg:hidden btn-ghost mt-3 px-4 pt-3">
                        <img src={menuIcon} alt="" className='w-[50px]' />
                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg> */}
                    </label>
                    <ul tabIndex={0} className="menu dropdown-content mt-3 p-2 shadow bg-base-100 w-52">
                        {menu}
                    </ul>
                </div>
                <Link to="/">
                    <img src={logo} alt="" className='w-[250px] lg:w-[350px]' />
                </Link>
            </div>
            <div className="navbar-center hidden lg:flex w-[70%]">
                <ul className="menu menu-horizontal px-1 font-bold text-lg w-full justify-center gap-6">
                    {menu}
                </ul>
            </div>
            <div className="navbar-end md:w-[20%]">
                <Link to="/contact-us" className="bg-red-500 border-hidden hover:bg-red-700 font-bold text-white text-md p-2 md:p-4 rounded-xl">Contact Us</Link>
            </div>
        </div>
    );
};

export default Navbar;
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../Context/AdminContext';
import { toast } from 'react-hot-toast';
import logo from "../../../Assets/Images/admin-login.png";
import brLogo from "../../../Assets/Images/br-logo-01.png";

const AdminLogin = () => {
    const { signIn, loading } = useContext(AuthContext);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/adminDashboard';

    const handleLogin = (e) => {
        e.preventDefault();
        const form = e.target;
        const username = e.target.username.value;
        const password = e.target.password.value;
        signIn(username, password)
            .then((res) => {
                form.reset();
                toast.success('Login Successful');
                navigate(from, { replace: true });
            })
            .catch((err) => {
                setError(err.message);
                toast.error(err.message);
            });
    };
    return (
        <div className="grid w-full md:grid-cols-2 items-center justify-center h-[100vh] mx-auto">
            <img src={logo} alt="" className='w-[80%] mx-auto hidden md:block' />
            <div className="w-full mx-auto">
                <img src={brLogo} alt="" className='w-[250px] mx-auto my-10' />
                <form onSubmit={handleLogin} className="bg-white shadow-2xl rounded-lg px-8 pt-6 pb-8 mb-4 md:h-[330px] w-[95%] md:w-[90%] lg:w-[80%] xl:w-[50%] mx-auto">
                    <div className="my-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                            Username
                        </label>
                        <input className="shadow appearance-none border border-red-600 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name='username' id="username" type="text" placeholder="Username" required />
                    </div>
                    <div className="my-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Password
                        </label>
                        <input className="shadow appearance-none border border-red-600 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" name='password' id="password" type="password" placeholder="Password" required />
                    </div>
                    <p className='text-red-500'>{error}</p>
                    <div className="flex items-center justify-center">
                        <button className="btn w-full bg-red-500 border-0 hover:bg-red-700" type="submit">
                            Sign In
                        </button>
                    </div>
                </form>
                <p className="text-center text-gray-500 text-xs">
                    &copy;2022 BlackRock IT Solutions. All rights reserved.
                </p>
            </div>
        </div>
    );
};

export default AdminLogin;
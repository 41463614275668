import React from 'react';
import Banner from '../Banner/Banner';
import ItStuffingServices from '../ItStuffingServices/ItStuffingServices';
import { Helmet } from 'react-helmet-async';
import { db } from '../../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";

const ItStuffing = () => {
    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'IT Stuffing'
    };
    push(ipPageRef, pageData);


    return (
        <div>
            <Helmet>
                <title>IT Stuffing</title>
                <meta
                    name="description"
                    content="BRITS is a leading provider of IT services and solutions. We are a team of experts who are passionate about technology and innovation. We are committed to helping our clients achieve their business goals."
                />
            </Helmet>
            <Banner></Banner>
            <ItStuffingServices></ItStuffingServices>
        </div>
    );
};

export default ItStuffing;
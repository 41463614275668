import React, { useEffect } from 'react';
import FileUploadForm from '../../Components/FileUploadForm/FileUploadForm';
import ReactGA from 'react-ga';
import { db } from '../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";


const AngularDeveloper = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.event({
            category: 'Job Openings',
            action: 'Angular Developer',
            label: 'Angular Developer',
        });
    }, []);

    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'Angular Developer'
    };
    push(ipPageRef, pageData);


    return (
        <div className='px-10'>
            <div className='py-20 bg-sky-500 mb-10'>
                <h1 className='text-center text-6xl text-white'>Angular Developer</h1>
            </div>
            <h1 className='text-2xl'><span className='font-bold'>Location:</span>     100% Remote</h1>
            <h1 className='font-bold text-2xl my-3'>Job Description:</h1>
            <p className=' text-gray-600'>
                Build modern HTML and JavaScript driven front-ends to achieve better quality, satisfy stakeholders and delight customers. <br />
                Be an expert on JavaScript and front-end frameworks, collaborate with developers, business partners, architects, and other groups to identify technical and functional needs of systems. <br />
                Responsible for key system design and integration decisions; and for communicating decisions to individuals implementing the system <br />
                Work with product owners to understand desired application capabilities and testing scenarios – Continuously improve software engineering practices
            </p>
            <h1 className='font-bold text-2xl my-3'>Qualifications</h1>
            <p className=' text-gray-600'>B.A or B.S or B.tech / Masters  Required</p>
            <h1 className='font-bold text-2xl my-3'>Required Skills/Experience</h1>
            <p className=' text-gray-500'>
                JavaScript <br />
                Angular
            </p>
            <h1 className='font-bold text-2xl my-3'>Recommended Skills/Experience</h1>
            <p className=' text-gray-500'>
                AWS <br />
                Design Patterns <br />
                CI CD Technologies
            </p>
            <FileUploadForm />
        </div>
    );
};

export default AngularDeveloper;
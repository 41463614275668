import React from 'react';
import "./borderCss.css";
const Recognition = () => {
    return (
        <div className='my-20 max-w-[970px] mx-auto'>
            <h1 className='text-5xl text-center text-red-500'>RECOGNITIONS</h1>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-10 borderCss rounded-2xl'>
                <div className='px-5 py-10 w-[325px] h-[190px]'>
                    <img src="https://www.nwboc.org/images/pasted-svg-540685x150.svg?crc=4071637462" alt="" className='h-[110px] mx-auto' />
                </div>
                <div className='px-5 py-10 w-[325px] h-[190px]'>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/25/U.S._Small_Business_Administration_logo.svg/2560px-U.S._Small_Business_Administration_logo.svg.png" alt="" className='h-[110px] mx-auto' />
                </div>
                <div className='px-5 py-10 w-[325px] h-[190px]'>
                    <img src="https://s3-us-west-2.amazonaws.com/cbi-image-service-prd/modified/11a95c1c-0606-4143-8bd7-efc2af240d9f.png" alt="" className='h-[110px] mx-auto' />
                </div>
                <div className='px-5 py-10 w-[325px] h-[190px]'>
                    <img src="https://searchlogovector.com/wp-content/uploads/2020/05/certified-wbenc-womens-business-enterprise-logo-vector.png" alt="" className='h-[110px] mx-auto' />
                </div>
                <div className='px-5 py-10 w-[325px] h-[190px]'>
                    <img src="https://static.wixstatic.com/media/a48d8c_c22fd7f4fb4142509a42f4ddbbcc0d27~mv2.png/v1/crop/x_0,y_0,w_599,h_745/fill/w_260,h_326,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/EDWOSB-Certified.png" alt="" className='h-[110px] mx-auto' />
                </div>
                <div className='px-5 py-10 w-[325px] h-[190px]'>
                    <img src="https://www.nwboc.org/images/nwboc%20w%2C%20v%2C%20ed%20final-01.png" alt="" className='h-[110px] mx-auto' />
                </div>
            </div>
        </div>
    );
};

export default Recognition;
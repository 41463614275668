import React, { useEffect } from 'react';
import placementAnimation from "../../../Assets/Images/selected-candidate.png";
import projectCompletedAnimation from "../../../Assets/Images/task-completed.png";
import contractAnimation from "../../../Assets/Images/contract.png";
import trainingAnimation from "../../../Assets/Images/online-training-course.png";
import Recognition from '../Recognition/Recognition';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';
import { db } from '../../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";


const Achievements = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        const trackingId = "G-RZNB3BG1X6";
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'Achievements'
    };
    push(ipPageRef, pageData);


    return (
        <div className='my-20 px-10'>
            <Helmet>
                <title>BRITS - Achievements</title>
                <meta
                    name="description"
                    content="BRITS is a leading provider of IT services and solutions. We are a team of experts who are passionate about technology and innovation. We are committed to helping our clients achieve their business goals."
                />
            </Helmet>
            <h1 className='text-5xl text-center text-red-500'>2022 ACHIEVEMENTS</h1>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 my-10 gap-5'>
                <div className='card shadow-lg px-3 py-4'>
                    <img src={placementAnimation} alt="" className='w-1/2 mx-auto h-[250px]' />
                    <hr className='border-2 w-1/2 mx-auto border-red-500' />
                    <h1 className='text-7xl text-center font-bold text-green-500'>20+</h1>
                    <hr className='border-2 w-1/2 mx-auto border-red-500' />
                    <p className='text-center font-semibold'>DIRECT HIRE PLACEMENTS <br />WITH FORTUNE <br />500 COMPANIES</p>
                </div>
                <div className='card shadow-lg px-3 py-4'>
                    <img src={projectCompletedAnimation} alt="" className='w-1/2 mx-auto h-[250px]' />
                    <hr className='border-2 w-1/2 mx-auto border-red-500' />
                    <h1 className='text-7xl text-center font-bold text-green-500'>15+</h1>
                    <hr className='border-2 w-1/2 mx-auto border-red-500' />
                    <p className='text-center font-semibold'>COMPLETED SOFTWARE <br />
                        DEVELOPMENTS <br />
                        PROJECTS</p>
                </div>
                <div className='card shadow-lg px-3 py-4'>
                    <img src={contractAnimation} alt="" className='w-1/2 mx-auto h-[250px]' />
                    <hr className='border-2 w-1/2 mx-auto border-red-500' />
                    <h1 className='text-7xl text-center font-bold text-green-500'>12+</h1>
                    <hr className='border-2 w-1/2 mx-auto border-red-500' />
                    <p className='text-center font-semibold'>ACTIVE CONTRACT <br />
                        TO CONTRACT <br />
                        PLACEMENTS</p>
                </div>
                <div className='card shadow-lg px-3 py-4'>
                    <img src={trainingAnimation} alt="" className='h-[250px]' />
                    <hr className='border-2 w-1/2 mx-auto border-red-500' />
                    <h1 className='text-7xl text-center font-bold text-green-500'>18+</h1>
                    <hr className='border-2 w-1/2 mx-auto border-red-500' />
                    <p className='text-center font-semibold'>TRAINING BATCHES <br />
                        COMPLETED</p>
                </div>
            </div>
            <Recognition />
        </div>
    );
};

export default Achievements;
import React, { useEffect } from 'react';
import { VscServerProcess } from "react-icons/vsc"
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
const Services = () => {
    const trackingId = "G-RZNB3BG1X6";
    ReactGA.pageview(window.location.pathname + window.location.search);

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.event({
            category: "User",
            action: "Visited Services Page"
        });
    }, []);


    return (
        <div className='py-14 px-4 md:px-10 lg:px-16 xl:px-36 bg-gray-700'>
            <h1 className='text-5xl text-center text-white'><VscServerProcess className='inline mr-4' />SERVICES</h1>
            <div className='w-3/4 mx-auto grid lg:grid-cols-2 my-5 gap-10'>
                <div className="card card-side shadow-xl px-5 py-3 border-4 border-red-600" data-aos="fade-right" data-aos-offset="200"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out">
                    <figure className='w-1/2'><img src="https://cdn-icons-png.flaticon.com/512/7991/7991055.png" alt="Movie" className='w-[100px]' /></figure>
                    <div className="card-body px-2">
                        <h2 className="card-title text-2xl text-white">SOFTWARE DEVELOPMENT</h2>
                        <div className="card-actions justify-end">
                            <Link to="/software-development" className="btn btn-sm bg-white text-black hover:bg-sky-400 hover:text-white">LEARN MORE</Link>
                        </div>
                    </div>
                </div>
                <div className="card card-side shadow-xl px-5 py-3 border-4 border-red-600" data-aos="fade-left" data-aos-offset="200"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out">
                    <figure className='w-1/2'><img src="https://cdn-icons-png.flaticon.com/512/2706/2706962.png" alt="Movie" className='w-[100px]' /></figure>
                    <div className="card-body px-2">
                        <h2 className="card-title text-2xl text-white">IT CONSULTING</h2>
                        <div className="card-actions justify-end">
                            <Link to="/it-consulting" className="btn btn-sm bg-white text-black hover:bg-sky-400 hover:text-white">LEARN MORE</Link>
                        </div>
                    </div>
                </div>
                <div className="card card-side shadow-xl px-5 py-3 border-4 border-red-600" data-aos="fade-right" data-aos-offset="200"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out">
                    <figure className='w-1/2'><img src="https://cdn-icons-png.flaticon.com/512/3785/3785174.png" alt="Movie" className='w-[100px]' /></figure>
                    <div className="card-body px-2">
                        <h2 className="card-title text-2xl text-white">IT MANAGED SERVICES</h2>
                        <div className="card-actions justify-end">
                            <Link to="/it-managed-services" className="btn btn-sm bg-white text-black hover:bg-sky-400 hover:text-white">LEARN MORE</Link>
                        </div>
                    </div>
                </div>
                <div className="card card-side shadow-xl px-5 py-3 border-4 border-red-600" data-aos="fade-left" data-aos-offset="200"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out">
                    <figure className='w-1/2'><img src="https://cdn-icons-png.flaticon.com/512/4762/4762311.png" alt="Movie" className='w-[100px]' /></figure>
                    <div className="card-body px-2">
                        <h2 className="card-title text-2xl text-white">IT TRAINING</h2>
                        <div className="card-actions justify-end">
                            <Link to="/it-training" className="btn btn-sm bg-white text-black hover:bg-sky-400 hover:text-white">LEARN MORE</Link>
                        </div>
                    </div>
                </div>
                <div className="card card-side shadow-xl px-5 py-3 border-4 border-red-600" data-aos="fade-right" data-aos-offset="200"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out">
                    <figure className='w-1/2'><img src="https://cdn-icons-png.flaticon.com/512/6802/6802091.png" alt="Movie" className='w-[100px]' /></figure>
                    <div className="card-body px-2">
                        <h2 className="card-title text-2xl text-white">IT STAFFING</h2>
                        <div className="card-actions justify-end">
                            <Link to="/it-staffing" className="btn btn-sm bg-white text-black hover:bg-sky-400 hover:text-white">LEARN MORE</Link>
                        </div>
                    </div>
                </div>
                <div className="card card-side shadow-xl px-5 py-3 border-4 border-red-600" data-aos="fade-left" data-aos-offset="200"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out">
                    <figure className='w-1/2'><img src="https://cdn-icons-png.flaticon.com/512/3141/3141181.png" alt="Movie" className='w-[100px]' /></figure>
                    <div className="card-body px-2">
                        <h2 className="card-title text-2xl text-white">DIGITAL MARKETING</h2>
                        <div className="card-actions justify-end">
                            <Link to="/digital-marketing" className="btn btn-sm bg-white text-black hover:bg-sky-400 hover:text-white">LEARN MORE</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
import React from 'react';

const Careers = () => {
    return (
        <div className='py-14 px-5 md:px-10 lg:px-16 xl:px-36 bg-red-100'>
            <h1 className='text-5xl text-center font-semibold'>CAREERS</h1>
            <div className='flex justify-evenly mt-5 px-5'>
                <div className='border-b-2 border-r-2 border-slate-800 p-2 w-full'>
                    <div className='md:h-[150px] flex items-center justify-center'>
                        <img src="https://foghornconsulting.com/wp-content/uploads/2022/02/Azure_Logo.png" alt="" className='w-3/4 md:w-[150px]' />
                    </div>
                    <h1 className='font-bold text-center'>AZURE DEVOPS</h1>
                </div>
                <div className='border-b-2 border-r-2 border-slate-800 p-2 w-full'>
                    <div className='md:h-[150px] flex items-center justify-center'>
                        <img src="https://kaskade.cloud/wp-content/uploads/2020/08/aws-logo.png" alt="" className='w-full md:w-[150px]' />
                    </div>
                    <h1 className='font-bold text-center'>AWS CLOUD</h1>
                </div>
                <div className='border-b-2 border-r-2 border-slate-800 w-full p-2'>
                    <div className='md:h-[150px] flex items-center justify-center'>
                        <img src="https://mulesoftenthusiast.files.wordpress.com/2022/03/cropped-icon-mulesoft-1.png?w=240" alt="" className='w-3/4 md:w-[150px]' />
                    </div>
                    <h1 className='font-bold text-center'>MULESOFT</h1>
                </div>
                <div className='border-b-2 border-slate-800 w-full p-2'>
                    <div className='md:h-[150px] flex items-center justify-center'>
                        <img src="https://i0.wp.com/dotweekly.com/wp-content/uploads/2018/04/salesforce-logo.png" alt="" className='w-3/4 md:w-[150px]' />
                    </div>
                    <h1 className='font-bold text-center'>SALESFORCE</h1>
                </div>
            </div>
            <div className='flex justify-evenly'>
                <div className='border-r-2 border-slate-800 w-full p-2'>
                    <div className='md:h-[150px] flex items-center justify-center'>
                        <img src="https://logolook.net/wp-content/uploads/2022/11/ServiceNow-Emblem.png" alt="" className='w-3/4 md:w-[150px]' />
                    </div>
                    <h1 className='font-bold text-center'>SERVICE-NOW</h1>
                </div>
                <div className='border-r-2 border-slate-800 w-full p-2'>
                    <div className='md:h-[150px] flex items-center justify-center'>
                        <img src="https://companieslogo.com/img/orig/PEGA-df8a22df.png?t=1597250644" alt="" className='w-3/4 md:w-[150px]' />
                    </div>
                    <h1 className='font-bold text-center'>PEGA</h1>
                </div>
                <div className='border-slate-800 w-full p-2'>
                    <div className='md:h-[150px] flex items-center justify-center'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/f/f8/Splunk_logo.png" alt="" className='w-3/4 md:w-[150px]' />
                    </div>
                    <h1 className='font-bold text-center'>SPLUNK</h1>
                </div>
            </div>
        </div>
    );
};

export default Careers;
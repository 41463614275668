import React from 'react';
import chartAnimation from "../../../Assets/Images/break-the-market.png"
import marketingAnimation from "../../../Assets/Images/millennium-market.png";
import portfolioAnimation from "../../../Assets/Images/expanding-the-portfolio.png";


const History = () => {
    return (
        <div className='py-14 px-4 md:px-10 lg:px-5 xl:px-20 bg-red-100'>
            <h1 className='text-5xl text-center font-semibold'>OUR HISTORY</h1>
            <div className='mx-auto rounded-2xl p-10 mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16' data-aos="fade-up" data-aos-offset="200"
                data-aos-duration="1500"
                data-aos-easing="ease-in-out">
                <div className="card bg-base-100 shadow-2xl">
                    <figure>
                        <img src={chartAnimation} alt="" className='h-[250px] p-5' />
                    </figure>
                    <div className="card-body pt-0">
                        <h2 className="card-title text-red-500">2019 | BREAK THE MARKET</h2>
                        <p className='text-lg'>BlackRock was founded in 2019 with the goal of delivering IT solutions and best practices once only available to the Fortune 100, making the solutions more consumable and affordable to the mid-market and emerging enterprise space.</p>
                    </div>
                </div>
                <div className="card bg-base-100 shadow-2xl">
                    <figure>
                        <img src={marketingAnimation} alt="" className='h-[250px]' />
                    </figure>
                    <div className="card-body pt-0">
                        <h2 className="card-title text-red-500">2020 | MILLENNIUM MARKET</h2>
                        <p className='text-lg'>BlackRock has established a proven track record of success as a managed service provider, partnering with our clients to provide outcome-driven and customer-centric service. We are proud of our 95% client retention rate at BlackRock, we listen, we care, and we are deeply committed to supporting our clients' digital transformation strategies.</p>
                    </div>
                </div>
                <div className="card bg-base-100 shadow-2xl">
                    <figure>
                        <img src={portfolioAnimation} alt="" className='h-[250px]' />
                    </figure>
                    <div className="card-body pt-0">
                        <h2 className="card-title text-red-500">2021 | EXPANDING THE PORTFOLIO</h2>
                        <p className='text-lg'>BlackRock certified engineering team possesses the depth of knowledge and expertise to deliver the most up-to-date and innovative solutions to our clients. We are proud to be a cloud first company and that our fractional consumption models make the most advanced capabilities a reality for our clients. We help small business and start up find 3% hidden talent and also grow their business and enter the enterprise space
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default History;
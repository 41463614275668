import React from 'react';
import Lottie from "lottie-react"
import keyword from "../../../Assets/Animations/keywords.json"
import optimization from "../../../Assets/Animations/optimization-dg.json"
import linkBuild from "../../../Assets/Animations/link-building.json"
import seo from "../../../Assets/Animations/seo.json"
import campaign from "../../../Assets/Animations/campaign.json"
import optimization2 from "../../../Assets/Animations/optimization.json"
import keyword2 from "../../../Assets/Animations/keywords-research.json"
import analysis from "../../../Assets/Animations/data-analysis.json"
import strategy from "../../../Assets/Animations/strategy.json"
import content from "../../../Assets/Animations/content-creation.json"
import community from "../../../Assets/Animations/community.json"
import advertising from "../../../Assets/Animations/advertising.json"

const DigitalMarketingServices = () => {
    return (
        <div className='px-10'>
            <h1 className='text-5xl text-center'>Welcome to Our Digital Marketing Services
            </h1>
            <p className='my-5'>Our team of experienced digital marketers can help you reach your target audience and achieve your business goals. We offer a wide range of digital marketing services, including search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, email marketing, and more.
            </p>
            <p className='my-5'><span className='text-3xl'>Search Engine Optimization (SEO)
            </span>
                <br />
                Our SEO services are designed to help your website rank higher in search engine results pages (SERPs), which can lead to more visibility and more traffic to your site. Some of the services we offer include:
            </p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2">
                <figure className='p-5 md:w-1/2 lg:w-[250px]'>
                    <Lottie
                        animationData={keyword}
                        className='w-full' ></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Keyword research
                    </h2>
                    <p>We can help you identify the keywords and phrases that are most relevant to your business, and which are most likely to be used by your target audience.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[250px]'>
                    <Lottie
                        animationData={optimization}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">On-page optimization
                    </h2>
                    <p>We can optimize your website's content and structure to ensure that it is optimized for search engines.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[250px]'>
                    <Lottie
                        animationData={linkBuild}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Link building
                    </h2>
                    <p>We can help you build high-quality backlinks to your site, which can improve your search engine rankings and drive more traffic to your site.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[550px]'>
                    <Lottie
                        animationData={seo}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">Local SEO
                    </h2>
                    <p>We can help you target customers within your geographical area. This is done by optimizing your website and online presence for local search queries, by creating and verifying Google My Business, adding your business to online directories and maps, and many other techniques. By doing so, your website will show up in search results when users look for businesses like yours in their local area.
                    </p>
                </div>
            </div>
            <p className='my-5'><span className='text-3xl'>Pay-Per-Click (PPC) Advertising
            </span>
                <br />
                Our PPC services can help you drive targeted traffic to your website by placing your ads on search engines and other websites. Our services include:
            </p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[250px]'>
                    <Lottie
                        animationData={campaign}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Campaign creation and management</h2>
                    <p>We can help you create and manage PPC campaigns on platforms like Google Ads, Bing Ads, and social media platforms.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[250px]'>
                    <Lottie
                        animationData={optimization2}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Ad copywriting and optimization</h2>
                    <p>We can write and optimize your ad copy to ensure that it is compelling and effective.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[250px]'>
                    <Lottie
                        animationData={keyword2}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Keyword research</h2>
                    <p>We can help you identify the keywords and phrases that are most relevant to your business, and which are most likely to be used by your target audience.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[250px]'>
                    <Lottie
                        animationData={analysis}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Tracking and analysis</h2>
                    <p>We can track the performance of your PPC campaigns and analyze the data to identify areas for improvement.
                    </p>
                </div>
            </div>
            <p className='my-5'><span className='text-3xl'>Social Media Marketing
            </span>
                <br />
                Our social media marketing services can help you reach your target audience and build your brand on social media platforms like Facebook, Twitter, Instagram, and LinkedIn. Our services include:
            </p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={strategy}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Social media strategy development</h2>
                    <p>We can help you create a social media marketing strategy that aligns with your business goals.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={content}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Content creation and curation</h2>
                    <p>We can help you create and curate content that is relevant and engaging for your target audience.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={community}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Community management</h2>
                    <p>We can help you engage with your followers and respond to comments and messages on your social media accounts.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={advertising}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Social media advertising</h2>
                    <p>We can help you create and manage social media advertising campaigns to reach a larger audience.
                    </p>
                </div>
            </div>
            <p className='my-5'><span className='text-3xl'>Email Marketing
            </span>
                <br />
                Our email marketing services can help you build relationships with your customers and drive conversions through targeted email campaigns. Our services include:
            </p>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={strategy}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Email marketing strategy development</h2>
                    <p>We can help you create a email marketing strategy that aligns with your business goals.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={advertising}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Campaign creation and management</h2>
                    <p>We can help you create and manage email campaigns that are targeted and effective.
                    </p>
                </div>
            </div>
            <div className="card lg:card-side bg-base-100 shadow-xl p-2 mt-5">
                <figure className='p-5 md:w-1/2 lg:w-[200px]'>
                    <Lottie
                        animationData={analysis}
                        className='w-full'></Lottie>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        Tracking and analysis</h2>
                    <p>We can track the performance of your email campaigns and analyze the data to identify areas for improvement.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default DigitalMarketingServices;
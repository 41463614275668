import React, { useEffect } from 'react';
import FileUploadForm from '../../Components/FileUploadForm/FileUploadForm';
import ReactGA from 'react-ga';
import { db } from '../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";

const AndroidDeveloper = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.event({
            category: "Android Developer",
            action: "Android Developer",
            label: "Android Developer",
        });
    }, []);

    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'Android Developer'
    };
    push(ipPageRef, pageData);


    return (
        <div className='px-10'>
            <div className='py-20 bg-sky-500 mb-10'>
                <h1 className='text-center text-6xl text-white'>Android Developer</h1>
            </div>
            <h1 className='text-2xl'><span className='font-bold'>Location:</span>     Phoenix,Az</h1>
            <h1 className='font-bold text-2xl my-3'>Job Description:</h1>
            <p className=' text-gray-600'>Must have 6 + years’ experience and out of which, at least 3 years’ experience must be in developing mobile application. <br />
                Should have led a team of at least 5 people in a Mobile application project. <br />
                Expertise in working with android Java.
                <br />
                Knowing enterprise application development is a plus
                <br />
                Must have a good understanding on Cocoa framework and runtime
                <br />
                Must have good debugging skills to identify and resolve issues
                <br />
                Must have a good understanding of Android design guidelines/Material Design
                <br />
                Good technical knowledge & understanding on Android frameworks & tools</p>
            <h1 className='font-bold text-2xl my-3'>Qualifications</h1>
            <p className=' text-gray-600'>B.A or B.S or B.tech / Masters  Required</p>
            <h1 className='font-bold text-2xl my-3'>Recommended Skills</h1>
            <p className=' text-gray-500'>
                Expertise in <span className='font-bold'>Java</span>, Kotlin or Both
            </p>
            <p className=' text-gray-500'>Vital Android SDK concepts</p>
            <p className=' text-gray-500'>Decent Experience with SQL</p>
            <p className=' text-gray-500'>Knowledge of Git</p>
            <p className='font-bold text-gray-500'>XML</p>
            <p className=' text-gray-500'>
                Understanding of <span className='font-bold'>Material Design</span>
            </p>
            <p className='font-bold text-gray-500'>Android Studio</p>
            <p className=' text-gray-500'>
                Backend <span className='font-bold'>Programming</span>
            </p>
            <h1 className='font-bold text-2xl my-3'>Required Skills</h1>
            <p className=' text-gray-500'>
                Java is the programming language that underpins all <span className='font-bold'>Android</span> development
            </p>
            <p className=' text-gray-500'>Understanding of XML. XML was created as a standard way to encode data for internet-based applications</p>
            <p className='font-bold text-gray-500'>Android SDK</p>
            <p className='font-bold text-gray-500'>Android Studio</p>
            <p className=' text-gray-500'>API's</p>
            <p className=' text-gray-500'>Databases</p>
            <p className=' text-gray-500'>Material Design</p>
            <FileUploadForm />
        </div>
    );
};

export default AndroidDeveloper;
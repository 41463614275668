import React, { useEffect } from 'react';
import FileUploadForm from '../../Components/FileUploadForm/FileUploadForm';
import ReactGA from 'react-ga';
import { db } from '../../Firebase/firebase.config.js';
import { ref, push } from "firebase/database";


const FullStackDeveloper = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.event({
            category: "Job Openings",
            action: "Full Stack Developer",
            label: "Full Stack Developer",
        });
    }, []);

    const ipPageRef = ref(db, 'pageViewData');
    const pageData = {
        pageName: 'Full Stack Developer'
    };
    push(ipPageRef, pageData);


    return (
        <div className='px-10'>
            <div className='py-20 bg-sky-500 mb-10'>
                <h1 className='text-center text-6xl text-white'>Full Stack Developer</h1>
            </div>
            <h1 className='text-2xl'><span className='font-bold'>Location:</span>     Phoenix,Az</h1>
            <h1 className='font-bold text-2xl my-3'>About this position :</h1>
            <p className=' text-gray-600'>
                Looking for Full Stack Developers to join a small team that is currently doing Agile Lean. <br /> Extreme Programming XP software development. looking for Developers to join this team that has a fast feedback cycle and constant interaction between stakeholders, designers, engineers and product managers. <br />
                The ideal candidate will have 2+ years of TDD experience, front end and backend coding experience, have worked
                with both customized and purchased applications, be able to provide ongoing support and troubleshoot, have Java, ReactJS, Spring Boot or Kotlin and understanding of AWS, or pivotal cloud foundry services and / or CI/CD.
            </p>
            <h1 className='font-bold text-2xl my-3'>Duties and Responsibilities :</h1>
            <p className=' text-gray-600'>
                Writing tests, as our process relies on test-driven development (TDD) <br />
                Working with other engineers to determine the best approach for any given feature, including refactoring to keep a
                clean codebase <br />
                Working with product owners as we evolve requirements, iterate through features, and gather feedback <br />
                Pair programming, because we know it delivers remarkable results <br />
                5-8+ years software engineering experience <br />
                2+ years in a leading role on project teams, with the ability to mentor and collaborate with other engineers
                2+ years of experience in Test Driven Development (TDD) and Agile <br />
                Working in an Agile/Lean/XP environment <br />
                Writing front-end and back-end code <br />
                Estimating complexity of user stories <br />
                Working with both custom developed and vended applications <br />
                Providing ongoing application support and troubleshooting <br />
                Remaining up to date on current standards, technologies, and frameworks <br />
                Being an advocate for healthy software development practices <br />
                Proactively identifying risk areas and opportunities for improvement
            </p>
            <h1 className='font-bold text-2xl my-3'>Qualifications</h1>
            <p className=' text-gray-600'>
                Bachelor s degree in Computer Science, Electrical/Electronic Engineering, Information Technology or another related
                field or Equivalent. <br />
                Ability to lead offshore team is required. <br />
                Ability to research, learn, and implement new technologies efficiently. <br />
                Ability to mentor others, work as a team, and delegate. Agile experience is a plus. <br />
                Should be proactively architecture and designing innovative solutions and make recommendations. <br />
                Will be advising team with new ideas, best practices and strategy on Front End /Backend Development for given
                applications. <br />
                Ability to think independently, work and communicate with others at all levels, and make progress efficiently. <br />
                Java, J2EE, SOAP and REST Services, JDBC, SQL (Oracle experience preferred), Angular framework. <br />
                Experience with tools such used in Microservice based systems like Spring Boot, Docker, Kubernetes, Netflix OSS tools,
                Kafka, etc. <br />
                Experience in containerization and CaaS platforms (OpenShift experience highly desired)
            </p>
            <h1 className='font-bold text-2xl my-3'>Recommended Skills/Experience</h1>
            <p className=' text-gray-500'>
                React, Java SpringBoot, Kotlin, Java <br />
                Demonstrated ability to quickly learn new languages <br />
                Strong collaboration skills and experience in pair programming <br />
                Experience working with multiple microservices on the back end <br />
                Need to work independently and able to work contribute when Pairing <br />
                Experience working in Lean/XP methodologies <br />
                Experience with Pivotal Cloud Foundry and CI/CD methodologies <br />
                Finance/Investment Consulting/Actuarial background <br />
                Education: Bachelor's degree or equivalent years of industry experience. <br />
                5+ years of SQL (Oracle preferred) experience. (Proficient) <br />
                8+ years of Java/J2EE experience. (Proficient) <br />
                8+ years of experience in written and oral communication with non-developers. (Proficient) <br />
                1+ years of Angular2+ experience.(Knowledge)
            </p>
            <FileUploadForm />
        </div>
    );
};

export default FullStackDeveloper;